import React, { useEffect, useState } from "react";
import { API_URL } from "../../Services/UserServices";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useAuth } from "../../provider/AuthProvider";
import withAuth from "../WithAuth";
import Calculator from "./Calculator";
import { useNavigate } from "react-router-dom";
import api from "../../Services/ApiService";
import text from "../../assets/TEXTS.png";



function Product() {
  const [products, setProduct] = useState([]);
  const [productName, setProductName] = useState("");
  const [unit, setUnit] = useState("SI");
  const [selectProduct, setSelectedProduct] = useState(null);
  const { user } = useAuth();
  const ModalButton = document.getElementById("modal-button");
  const closeButton = document.getElementById("close-modal");
  const [loading, setLoading] = useState(false);
  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(products.length / pageSize);
  const navigate = useNavigate();

  // Get the current page's data
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageProducts = products.slice(startIndex, endIndex);
  // const [productName, setProductName] = useState('');
  // const [unit, setUnit] = useState('');
  const [productNameError, setProductNameError] = useState('');
  const [unitError, setUnitError] = useState('');
  // const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false); // New state for showing errors

  // Function to navigate to a specific page
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };


  const [projectListLength, setProjectListLenth] = useState(0)
  const getProductList = async () => {
    addCountVisitPage();
    setLoading(true);
    try {
      const { data: res } = await api.get(API_URL + "/calculation/getAll");
      if (res.statusCode == 200) {
        setProduct(res.data);
        setProjectListLenth(res.data.length)
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);

      Swal.fire({
        text: e?.message
      });
    }
  };

  const getUserOne = async () => {
    setLoading(true);
    try {
      let user = JSON.parse(localStorage.getItem("user") ?? '{}');
      const { data: res } = await api.get(API_URL + "/user/getOne-user/" + user.id);
      if (res.statusCode == 200) {
        const currentObj = {
          "id": res?.data?.id,
          "name": user?.name,
          "email": user?.email,
          "role": "admin",
          "paid": res?.data?.paid,
          "userSubs": res?.data?.userSubs,
          "isFirstTimeLogin": res?.data?.isFirstTimeLogin,
          "subscription": res?.data?.subscription,
          "token": user?.token
        }

        localStorage.setItem('user', JSON.stringify(currentObj));
        if (res?.data?.paid !== "paid" && res?.data?.subscription === false) {
          setButtonStatus(true)
        }
        else {
          setButtonStatus(false)
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        text: e?.message
      });
    }
  };

  const [validDays, setValidDays] = useState('')

  const getSubList = async () => {
    setLoading(true);
    try {
      const { data: res } = await api.get(API_URL + "/user-subscription/get-remaining-day");
      let message = '';
      if (res.statusCode == 200) {
        //setValidDays(res.data)
        if (res.data > 0) {
          if (res.data > 2 && res.data < 365) {
            message = `Your subscription will expire in ${res.data} days`;
          } else if (res.data === 2) {
            message = 'Your subscription will expire the next 1 day';
          } else {
            message = 'Your subscription will expire for today only';
          }
        } else {
          message = 'Your Subscription Expired';
          setButtonStatus(true);
        }
        setLoading(false);
      }
      else if (res.data === false) {
        message = 'Your Subscription Expired';
        setButtonStatus(true);
      }
      setValidDays(message)
      setLoading(false);
    } catch (e) {
      setLoading(false);

      Swal.fire({
        text: e?.message
      });
    }


  };

  const addCountVisitPage = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user") ?? '{}');
    try {
      const { data: res } = await api.get(API_URL + "/user-analytics/" + user?.id);
      if (res.statusCode == 200) {
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);

      Swal.fire({
        text: e?.message
      });
    }


  };

  const [loginType, setLoginType] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(false)
  useEffect(() => {
    getProductList();
    getSubList();
    getUserOne();
    const user = JSON.parse(localStorage.getItem("user") ?? '{}');
    if (user?.isFirstTimeLogin) {
      setLoginType(true)
    }

  }, []);


  const handleSubmit = async () => {
    setShowErrors(true); // Show errors on submission
    // Validation
    let isValid = true;

    if (productName.trim() === '') {
      setProductNameError('project name cannot be empty');
      isValid = false;
    } else if (productName.replace(/\s+/g, '').length < 3) {
      setProductNameError('project name must have at least 3 non-space characters');
      isValid = false;
    } else if (productName.length > 55) {
      setProductNameError('project name must be at most 55 characters');
      isValid = false;
    } else {
      setProductNameError('');
    }

    if (!unit) {
      setUnitError('Please select a unit');
      isValid = false;
    } else {
      setUnitError('');
    }

    if (!isValid) {
      return;
    }

    try {

      setLoading(true);

      let recordData;
      if (selectProduct) {
        recordData = {
          id: selectProduct?.id,
          productName,
          unit,
          userId: user?.id
        }
      }
      else {
        recordData = {
          id: 0,
          productName,
          unit,
          ductData: [],
          children: [],
          userId: user?.id
        }
      }

      const { data: res } = await axios.post(
        API_URL + "/calculation/create-update",
        recordData,
        { headers: { Authorization: `bearer ${user?.token}` } }
      );
      if (res?.statusCode == 200 || res?.statusCode == 201) {
        getProductList();
        setProductName("");
        setUnit("SI");
        closeButton.click();
        setLoading(false);
        setSelectedProduct(null);
        getUserOne();
        // Swal.fire(
        //   selectProduct
        //     ? "Project updated successfully."
        //     : "Project created successfully."
        // );
      } else {
        setLoading(false);
        Swal.fire(res?.message);
      }
    } catch (error) {
      setLoading(false);

      if (error?.response?.status == 401) {
        localStorage.removeItem("user")
        window.location.href = '/Signup';
        return
      }

      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data.message
      ) {
        Swal.fire({
          icon: "error",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "An error occurred. Please try again later.",
        });
      }
      console.error(error);
    }
  };

  const show = (project) => {
    getUserOne();

    localStorage.setItem("projectID", project.id);
    navigate("/Calculator");
  };

  const edit = (project) => {
    setSelectedProduct(project);
    setProductName(project?.productName ?? "");
    setUnit(project?.unit ?? "");
    ModalButton?.click();
  };

  const deleteProduct = async (product) => {
    try {
      const confirmDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this project!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (confirmDelete.isConfirmed) {
        setLoading(true);

        const { data: res } = await axios.get(
          API_URL + `/calculation/delete/${product.id}`,
          {
            headers: { Authorization: `bearer ${user?.token}` },
          }
        );

        if (res.statusCode === 200) {
          getProductList();
          Swal.fire("Deleted!", "The product has been deleted.", "success");
        } else {
          Swal.fire(
            "Error",
            res?.message || "Failed to delete the product.",
            "error"
          );
        }

        setLoading(false);
        window.location.reload()
      }
    } catch (e) {

      setLoading(false);
      if (e?.response?.status == 401) {
        localStorage.removeItem("user")
        window.location.href = '/Signup';
      } else {
        Swal.fire({
          icon: "error",
          text: e.message || "An error occurred while deleting the product.",
        });
      }
    }
  };

  const goToCalculation = async (projectId) => {
    sessionStorage.setItem("projectId", projectId);
    window.location.href = "/Calculator";
  };

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ margin: "5%", minHeight: "320px" }}
      >
        <div className="spinner-border m-5 text-center" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }


  return (
    <div>
      <section className="container-fluid Project-section" >
        <div className="container"  >
          <div className="row mb-5 ">
            <div className="col-md-12 mt-4 text-center">
              <div className="d-flex align-items-center justify-content-center">
                <h5 className="fw-bold me-3">{validDays}</h5>
                <button type="button" className="btn btn-primary" onClick={() => window.location.href = "/Buynow"}>
                  Buy Now
                </button>

              </div>

            </div>
            <div className="col-md-12 mt-3">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <h3 className="fw-bold me-1">Projects List</h3>

                {buttonStatus !== true ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="modal-button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i className="fa-solid fa-plus me-1"></i> New Project
                  </button>) : <></>}
              </div>
            </div>

            <div className="col-md-12 my-3">
              {projectListLength == 0 ? (
                <>
                  <div className="py-1 text-center">
                    <img src={text} />
                  </div>
                </>
              ) : (
                <div className="col-md-12 mt-4">
                  <div className="card border-0">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr className="table-header">
                              <th>S. No.</th>
                              <th>Project Name</th>
                              <th>Unit System</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.length == "0" ? (
                              <tr>
                                {" "}
                                <td colSpan="4" className="text-center">
                                  No Data Found
                                </td>{" "}
                              </tr>
                            ) : (
                              currentPageProducts?.map((product, index) => (
                                <tr key={index}>
                                  <td>{startIndex + index + 1}</td>
                                  <td>{product?.productName}</td>
                                  <td>{product?.unit?.toUpperCase()}</td>
                                  <td>
                                    <div className="table-action-icon">
                                      {/* <a onClick={() => goToCalculation(product.id)}>
                                  <i className="fa-regular fa-eye"></i>
                                </a> */}
                                      <a onClick={() => show(product)}>
                                        <i className="fa-regular fa-eye"></i>
                                      </a>
                                      {buttonStatus !== true ? (
                                        <>
                                          <a onClick={() => edit(product)}>
                                            <i className="fa-regular fa-pen-to-square"></i>
                                          </a>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      <a onClick={() => deleteProduct(product)}>
                                        <i className="fa-regular fa-trash-can"></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {/* Pagination Controls */}
                        {products.length == "0" ? (
                          <></>
                        ) : (
                          <div className="d-flex justify-content-end">
                            <div className="pagination m-1">
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => goToPage(currentPage - 1)}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              <span className="mx-3">
                                {" "}
                                Page {currentPage} of {totalPages}
                              </span>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => goToPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary ">
              <h5 className="modal-title text-white " id="exampleModalLabel">
                {selectProduct ? "Update Project" : "Add New Project"}
              </h5>
              <button
                type="button"
                className="close"
                id="close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => { setSelectedProduct(null); setProductName(""); setProductNameError(''); setUnit("SI"); setUnitError(''); }
                }
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label className="m-1">Project Name</label>
              <input
                type="text"
                className="form-control"
                // placeholder="Ex- Mechinical Calculator"
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                  setProductNameError(''); // Clear the error message
                }}
              />
              {showErrors && productNameError && (
                <p className="error-message">{productNameError}</p>
              )}
              <br />
              <label className="m-1">Unit System</label>
              {selectProduct ?
                <><div
                  className="form-control" style={{ backgroundColor: "lightgrey" }} disabled
                >{unit}</div>
                </> : <select
                  className="form-select"
                  value={unit}
                  onChange={(e) => {
                    setUnit(e.target.value);
                    setUnitError(''); // Clear the error message
                  }}
                >

                  <option value={"SI"}>SI</option>
                  <option value={"Imperial"}>IMPERIAL</option>

                </select>}
              {showErrors && unitError && (
                <p className="error-message">{unitError}</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Product);
