import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../Services/UserServices';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import Sign_Up from "../../src/assets/Sign_Up.png";


function OtpConfirm() {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [otpTouched, setOtpTouched] = useState(false)
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error message
  const onSubmitHandlerResendOtp = async (e) => {
    setLoading(true)
    try {
      e.preventDefault();
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for the "Forgot Password" API
      const response = await axios.post(
        `${API_URL}/user/forget-password?` + `email=${localStorage.getItem('userEmail')}`,
      );


      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response.data.statusCode === 200) {
        // Show a success message to the user
        Swal.fire({
          title: 'Success!',
          text: " Otp resend successfully",
          icon: 'success',
          button: 'OK',
        });

      }
    } catch (error) {

      Swal.fire({
        title: 'Error!',
        text: error?.message,
        icon: 'error',
        button: 'OK',
      });
    }
    setLoading(false)
  };

  const onSubmitHandlerOtpConfirm = async (e) => {
    try {
      e.preventDefault();
      const trimmedOtp = otp.trim();
      if (trimmedOtp === '') {
        setOtpTouched(true); // Mark otp field as touched
        return; // Return early if otp is empty
      }
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server for OTP verification
      const response = await axios.post(
        `${API_URL}/user/verify`, { email: localStorage.getItem('userEmail'), otp: otp } // Pass the entered OTP to the API in the request body
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response.data.statusCode === 200) {
        // Show a success message to the user
        Swal.fire({
          title: 'Success!',
          text: 'OTP has been successfully verification ',
          icon: "success",
          button: 'OK',
        });
        navigate('/ResetPassword')
      }
      else {
        setErrorMessage('failed to verify otp.');
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Network Error.',
        icon: 'error',
        button: 'OK',
      });
    }
  };


  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
        </Box>
      )
      }
      <form onSubmit={onSubmitHandlerOtpConfirm}>
        <section className="container-fluid Sign-section  Sign-section-Password">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <h3 className="fw-bold"> Verify OTP</h3>
                        {/* <p className="Sign_Up_subheading">
                          On the other hand, we denounce with righteous indignation and dislike
                          men who are so beguiled and demoralized by the charms of pleasure of the
                          moment.
                        </p> */}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">OTP<span className="redP">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                        />
                        {otpTouched && otp.trim() === '' && <div className="redP">otp required</div>}
                        {errorMessage && <span className="redP">{errorMessage}</span>}
                      </div>
                      <div className="text-end">
                        <button className="forget_password" onClick={onSubmitHandlerResendOtp} type="button" >
                          Resend OTP
                        </button>
                      </div>
                      <div>
                        <button className="btn Sign_btn_submit" type="submit">
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="card Sign-Up-card-section-img">
                  <div className="card-body">
                    <img src={Sign_Up} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
}

export default OtpConfirm;
