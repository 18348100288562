import axios from "axios";

const api = axios.create({
  baseURL: "https://ductsizerpro.com/api/", // Replace with your API's base URL
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // You can add headers or perform other tasks before the request is sent
    const user = JSON.parse(localStorage.getItem("user") || "[]"); // Replace with how you store your token
    if (user) {
      config.headers.Authorization = `Bearer ${user?.token ?? ""}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // You can process successful responses here
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Redirect to the login page on 401 error
      localStorage.clear();
      window.location.href = "/Signup"; // Replace with your login page URL
    }
    return Promise.reject(error);
  }
);

export default api;
