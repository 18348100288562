import "nouislider/distribute/nouislider.css";
import { useEffect, useRef, useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import DirectoryTreeView from "../TreeView";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import axios from "axios";
import { API_URL } from "../../Services/UserServices";
import { useAuth } from "../../provider/AuthProvider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Backdrop, CircularProgress } from "@mui/material/index";
import { useNavigate } from "react-router-dom";
import DuctsList, { flattenArray } from "../DuctsList";
import * as XLSX from "xlsx";
import "sheetjs-style";
import { Helmet } from "react-helmet";
const FreeCalculator = () => {
  const { user } = useAuth();
  const [Cbunit, setCbunit] = useState("SI"); // Set SI as the default unit
  const [ducts, setDucts] = useState([]);

  const [height, setHeight] = useState(0);

  const [cblock, setCblock] = useState(false);

  const [selected, setSelected] = useState(null);
  const [sliderValue, setSliderValue] = useState(null);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    left: 0,
    top: 0,
  });
  const navigate = useNavigate();
  const [ductName, setDuctName] = useState();
  const [unpaidUserData, setUnpaidUserData] = useState({
    name: "",
    airQuantity: "",
    frictionLoss: "",
    velocity: "",
    frictionStatus: false,
    frictioninputStatus: false,
    velocityStatus: false,
    velocityinputStatus: false,
    diaMeter: "",
    width: "",
    height: 200,
    flowArea: "",
    widthStatus: true,
    width1: "",
    width2: "",
    width3: "",
    width4: "",
    width5: "",
    width6: "",
    lockStatus: false,
    remark: "",
  });

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState();

  const getProjectData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/calculation/getOne/${localStorage.getItem("projectID")}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        const projectData = response.data.data;
        setCbunit(projectData.unit);
        setDucts(projectData.ductData);
        setProject(projectData.productName);
      }
    } catch (e) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("user");
        window.location.href = "/Signup";
      } else {
        Swal.fire({
          icon: "error",
          text: e.message,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?.paid === "paid") {
      getProjectData();
    }
  }, [user]);

  const [buttonStatus, setButtonStatus] = useState(false);

  // useEffect(()=>{
  //   const user = JSON.parse(localStorage.getItem("user"));

  //   if(user?.paid !== "paid" && user?.subscription === false)
  //   {
  //       setButtonStatus(true)
  //   }

  //   else
  //   {
  //     setButtonStatus(false)
  //   }
  // },[buttonStatus])

  //New Code

  const getwidth = (d, h) => {
    let w = 10;
    while (
      -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
      0.00000001
    ) {
      w += 1;
    }
    return w;
  };

  const calculateDiameterFromFriction = (flow, fr) => {
    const dia =
      0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86);

    return dia;
  };

  const calculateFrictionFromVelocity = (velo, dia) => {
    const fr =
      ((0.0129 / 0.9) * 0.9 * Math.pow(velo, 1.82)) / Math.pow(dia, 1.22);
    return fr;
  };

  const calculateVelocityFromDiameter = (flow, dia, fr) => {
    const velo = Math.pow(
      (fr * Math.pow(dia, 1.22)) / (0.0129 * 0.9),
      1 / 1.82
    );
    return velo;
  };

  const calculateDuctWidth = (dia, h, r) => {
    let w = getwidth(dia, parseInt(h ? h : 0));
    w = Math.round(w / r) * r;
    return w;
  };

  const calculateDiameterFromVelocity = (flow, velo) => {
    const area = flow / 1000 / velo;
    const dia = Math.sqrt((area * 4) / Math.PI);
    return dia;
  };

  function calculateVelo(fr, dia) {
    return 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
  }
  function calculateDia(fr, flow) {
    return (
      0.0344198 * Math.pow(0.9 / fr, 1 / 4.86) * Math.pow(flow, 1.82 / 4.86)
    );
  }

  //Main Function
  const [unitsBoxStatus, setUnitsBoxStatus] = useState(false);

  const handleMainCalculation = (type) => {
    setLoading(true);
    if (type === "SI") {
      // setUnitsBoxStatus(true)
      handleCalculations();
    } else {
      // setUnitsBoxStatus(true)
      handleImperialCalculation();
    }
    setLoading(false);
  };

  const handleCalculations = () => {
    let h = 200;
    let flow = 0;
    let dia = 0;
    let fr = 0;
    let r = 50;
    let h1 = 250,
      h2 = 300,
      h3 = 350,
      h4 = 400,
      h5 = 500,
      h6 = 600;
    let width1 = "",
      width2 = "",
      width3 = "",
      width4 = "",
      width5 = "",
      width6 = "";
    let level = 1;

    if (user?.paid === "paid") {
      try {
        if (!selectedDuct?.name) {
          Swal.fire("Select Duct/Branch.");
          return;
        }
        let velo;

        if (
          !selectedDuct?.airQuantity ||
          isNaN(parseFloat(selectedDuct?.airQuantity))
        ) {
          Swal.fire("Please Enter Air Quantity.");
          return;
        }
        const convertFactor = Cbunit === "Imperial" ? 0.0254 : 1;
        if (Cbunit === "SI") {
          flow = selectedDuct.airQuantity;
        } else if (Cbunit === "Imperial") {
          flow = selectedDuct.airQuantity / 2.1188799727597;
          h *= 25.4;
          r = 50.8;
        }

        if (selectedDuct.lockStatus === false) {
          if (level <= 0) {
            return;
          }

          if (!selectedDuct.frictionLoss && !selectedDuct.velocity) {
            Swal.fire("Please provide either friction loss or velocity");
            return;
          }

          if (selectedDuct.frictionLoss && selectedDuct.velocity) {
            Swal.fire("Please delete either friction loss or velocity");
            return;
          }

          if (selectedDuct.frictionLoss) {
            fr = selectedDuct.frictionLoss;
            if (Cbunit === "Imperial") {
              fr *= 249.08890833333 / 30.48;
            }
            dia = calculateDiameterFromFriction(flow, fr);
            velo = calculateVelocityFromDiameter(flow, dia, fr);
          } else if (selectedDuct.velocity) {
            velo = selectedDuct.velocity;
            if (Cbunit === "Imperial") {
              velo *= 196.8;
            }
            dia = calculateDiameterFromVelocity(flow, velo);
            fr = calculateFrictionFromVelocity(velo, dia);
          }

          let w = calculateDuctWidth(dia, selectedDuct?.height, r);
          var veloResult = undefined;
          if (selectedDuct.frictionLoss !== "") {
            veloResult = calculateVelo(
              selectedDuct.frictionLoss,
              calculateDia(selectedDuct.frictionLoss, flow)
            ).toFixed(2);
          }

          selectedDuct.width = w;
          selectedDuct.diaMeter = Math.round((dia * 1000).toFixed(2));
          selectedDuct.velocity = veloResult !== undefined ? veloResult : velo;
          if (typeof fr === "number") {
            selectedDuct.frictionLoss = fr.toFixed(2);
          }
          //selectedDuct.frictionLoss = fr?.toFixed(2);
          selectedDuct.flowArea = (flow / 1000 / velo).toFixed(2);

          for (let i = 1; i <= 6; i++) {
            const hi = eval(`h${i}`);
            const width = calculateDuctWidth(dia, hi * convertFactor, r);
            eval(`width${i} = width`);
          }
        }

        if (selectedDuct.lockStatus === true) {
          if (selectedDuct.width === "" && height === "" && h === 0) {
            if (Cbunit === "Imperial") {
              r *= 25.4;
            }
            dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
            fr =
              (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
              Math.pow(dia / 1000, 4.86);

            selectedDuct.diaMeter = Math.round(
              dia * (Cbunit === "Imperial" ? 0.0254 : 1),
              0
            );
            selectedDuct.frictionLoss = Math.round(
              fr * (Cbunit === "Imperial" ? 30.48 / 249.08890833333 : 1),
              2
            );
            selectedDuct.velocity = Math.round(
              10.91 *
                Math.pow(fr, 0.54) *
                Math.pow(dia / 1000, 0.67) *
                (Cbunit === "Imperial" ? 196.85 : 1),
              1
            );
            selectedDuct.flowArea = (
              flow /
              1000 /
              selectedDuct.velocity
            ).toFixed(2);
          }
        }

        if (selectedDuct.frictioninputStatus === true) {
          selectedDuct.frictioninputStatus = true;
          selectedDuct.frictionStatus = true;
        } else {
          selectedDuct.velocityinputStatus = true;
          selectedDuct.velocityStatus = true;
        }
        selectedDuct.width1 = width1;
        selectedDuct.width2 = width2;
        selectedDuct.width3 = width3;
        selectedDuct.width4 = width4;
        selectedDuct.width5 = width5;
        selectedDuct.width6 = width6;
        const updatedDucts = updateDuctList(ducts);
        setDucts(updatedDucts);
        setSelectedDuct(selectedDuct);
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    } else {
      try {
        let velo;
        if (
          !unpaidUserData?.airQuantity ||
          isNaN(parseFloat(unpaidUserData?.airQuantity))
        ) {
          Swal.fire("Please Enter Air Quantity.");
          return;
        }

        const convertFactor = Cbunit === "Imperial" ? 0.0254 : 1;

        if (Cbunit === "SI") {
          flow = unpaidUserData.airQuantity;
        } else if (Cbunit === "Imperial") {
          flow = unpaidUserData.airQuantity / 2.1188799727597;
          h *= 25.4;
          r = 50.8;
        }

        if (unpaidUserData.lockStatus === false) {
          if (level <= 0) {
            return;
          }

          if (!unpaidUserData.frictionLoss && !unpaidUserData.velocity) {
            Swal.fire("Please provide either friction loss or velocity");
            return;
          }

          if (unpaidUserData.frictionLoss && unpaidUserData.velocity) {
            Swal.fire("Please delete either friction loss or velocity");
            return;
          }

          if (unpaidUserData.frictionLoss) {
            fr = unpaidUserData.frictionLoss;
            if (Cbunit === "Imperial") {
              fr *= 249.08890833333 / 30.48;
            }
            dia = calculateDiameterFromFriction(flow, fr);
            velo = calculateVelocityFromDiameter(flow, dia, fr);
            // velo = velo.toFixed(2);
          } else if (unpaidUserData.velocity) {
            velo = unpaidUserData.velocity;
            if (Cbunit === "Imperial") {
              velo *= 196.8;
            }
            dia = calculateDiameterFromVelocity(flow, velo);
            fr = calculateFrictionFromVelocity(velo, dia);
            //  velo = velo.toFixed(2);
          }

          let w = calculateDuctWidth(dia, unpaidUserData?.height, r);

          var veloResult = undefined;
          if (unpaidUserData.frictionLoss !== "") {
            veloResult = calculateVelo(
              unpaidUserData.frictionLoss,
              calculateDia(unpaidUserData.frictionLoss, flow)
            ).toFixed(2);
          }
          unpaidUserData.width = w;
          unpaidUserData.diaMeter = Math.round((dia * 1000).toFixed(2));
          if (typeof velo === "number") {
            unpaidUserData.velocity =
              veloResult !== undefined ? veloResult : velo.toFixed(2);
          }

          const frNumber = parseFloat(fr);
          unpaidUserData.frictionLoss = frNumber.toFixed(2);
          unpaidUserData.flowArea = (flow / 1000 / velo).toFixed(2);
          for (let i = 1; i <= 6; i++) {
            const hi = eval(`h${i}`);
            const width = calculateDuctWidth(dia, hi * convertFactor, r);
            eval(`width${i} = width`);
            unpaidUserData[`width${i}`] = width;
          }
        }

        if (unpaidUserData.lockStatus === true) {
          if (unpaidUserData.width === "" && height === "" && h === 0) {
            if (Cbunit === "Imperial") {
              r *= 25.4;
            }
            dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
            fr =
              (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
              Math.pow(dia / 1000, 4.86);

            unpaidUserData.diaMeter = Math.round(
              dia * (Cbunit === "Imperial" ? 0.0254 : 1),
              0
            );
            unpaidUserData.frictionLoss = Math.round(
              fr * (Cbunit === "Imperial" ? 30.48 / 249.08890833333 : 1),
              2
            );
            unpaidUserData.velocity = Math.round(
              10.91 *
                Math.pow(fr, 0.54) *
                Math.pow(dia / 1000, 0.67) *
                (Cbunit === "Imperial" ? 196.85 : 1),
              1
            );
            unpaidUserData.flowArea = (
              flow /
              1000 /
              unpaidUserData.velocity
            ).toFixed(2);
          }
        }

        if (unpaidUserData.frictioninputStatus === true) {
          unpaidUserData.frictioninputStatus = true;
          unpaidUserData.frictionStatus = true;
        } else {
          unpaidUserData.velocityinputStatus = true;
          unpaidUserData.velocityStatus = true;
        }
        setUnpaidUserData({ ...unpaidUserData });
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    }
    setUnitsBoxStatus(true);
  };

  const updateDuctList = (ducts) => {
    return ducts.map((duct) => {
      if (duct.name === selectedDuct.name) {
        return {
          ...duct,
          ["airQuantity"]: selectedDuct.airQuantity,
          ["diaMeter"]: Math.round(selectedDuct.diaMeter),
          ["flowArea"]: selectedDuct.flowArea,
          ["frictionLoss"]: selectedDuct?.frictionLoss,
          ["frictionStatus"]: selectedDuct.frictionStatus,
          ["name"]: selectedDuct.name,
          ["velocity"]: selectedDuct.velocity,
          ["velocityStatus"]: selectedDuct.velocityStatus,
          ["width"]: selectedDuct.width,
          ["width1"]: selectedDuct.width1,
          ["width2"]: selectedDuct.width2,
          ["width3"]: selectedDuct.width3,
          ["width4"]: selectedDuct.width4,
          ["width5"]: selectedDuct.width5,
          ["width6"]: selectedDuct.width6,
          ["widthStatus"]: selectedDuct.widthStatus,
          ["lockStatus"]: selectedDuct.lockStatus,
          ["remark"]: selectedDuct.remark,
        };
      } else if (duct.children && duct.children.length > 0) {
        return {
          ...duct,
          children: updateDuctList(duct.children),
        };
      }
      return duct;
    });
  };

  const findRecord = (branchName, data) => {
    for (const item of data) {
      if (item.name === branchName) {
        return item;
      }
      if (item.children.length > 0) {
        const found = findRecord(branchName, item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  //Imeperial Code

  const calcuateImperialFlowArea = () => {
    return Math.round(
      selectedDuct.airQuantity /
        2.1188799727597 /
        1000 /
        (selectedDuct.velocity / 196.8) /
        (0.0254 * 0.0254)
    );
  };
  const CalcualteImperialDuctDiameter = (FlowArea) => {
    return Math.round(Math.pow((FlowArea * 4) / Math.PI, 0.5));
  };
  // function calculateFrictionLoss(velocity, diameter) {
  //   const numerator = 0.0129 / 0.9;
  //   const velocityTerm = Math.pow(velocity / 196.8, 1.82);
  //   const denominator = Math.pow(diameter, 1.22);
  //   const fr = (numerator * velocityTerm) / denominator;
  //   return fr;
  // }

  function calculateFrictionLoss(flow, txtVelocity, txtRoughness) {
    var adjustedVelocity = txtVelocity / 196.8;
    var area = flow / 2.1188799727597 / 1000 / adjustedVelocity;
    var dia = Math.sqrt((area * 4) / Math.PI);
    var fr =
      ((0.0129 / 0.9) * txtRoughness * Math.pow(adjustedVelocity, 1.82)) /
      Math.pow(dia, 1.22);
    return fr * 0.0040146307866177 * 30.48;
  }

  function getwidthImperial(d, h) {
    let w = 10;
    while (
      -(1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25) + d * 1000 >
      0.00000001
    ) {
      w++;
    }
    w = Math.round(w / 50.8) * 2;
    return w;
  }

  const handleImperialCalculation = () => {
    if (user?.paid === "paid") {
      if (!selectedDuct.name) {
        Swal.fire("Select Duct/Branch.");
        return;
      }
      if (
        !selectedDuct?.airQuantity ||
        isNaN(parseFloat(selectedDuct?.airQuantity))
      ) {
        Swal.fire("Please Enter Air Quantity.");
        return;
      }

      if (!selectedDuct.frictionLoss && !selectedDuct.velocity) {
        Swal.fire("Please provide either friction loss or velocity");
        return;
      }

      if (selectedDuct.frictionLoss && selectedDuct.velocity) {
        Swal.fire("Please delete either friction loss or velocity");
        return;
      }
      if (selectedDuct.velocity) {
        let FlowArea = calcuateImperialFlowArea();
        let RoundDuctDiameter = CalcualteImperialDuctDiameter(FlowArea);
        let frictionLoss = calculateFrictionLoss(
          selectedDuct.airQuantity,
          selectedDuct.velocity,
          0.9
        );
        let h = selectedDuct.height;
        h *= 25.4;
        let r = 50.8;

        let flow = selectedDuct.airQuantity / 2.1188799;
        let area = flow / 1000 / (selectedDuct.velocity / 196.8);
        let dia = Math.pow((area * 4) / Math.PI, 0.5);

        selectedDuct.width = getwidthImperial(dia, h);

        selectedDuct.diaMeter = RoundDuctDiameter;
        selectedDuct.frictionLoss = frictionLoss?.toFixed(2);
        selectedDuct.flowArea = FlowArea;

        selectedDuct.h1 = 12;
        selectedDuct.h2 = 14;
        selectedDuct.h3 = 16;
        selectedDuct.h4 = 18;
        selectedDuct.h5 = 20;
        selectedDuct.h6 = 22;

        selectedDuct.width1 = getwidthImperial(dia, selectedDuct.h1 * 25.4);
        selectedDuct.width2 = getwidthImperial(dia, selectedDuct.h2 * 25.4);
        selectedDuct.width3 = getwidthImperial(dia, selectedDuct.h3 * 25.4);
        selectedDuct.width4 = getwidthImperial(dia, selectedDuct.h4 * 25.4);
        selectedDuct.width5 = getwidthImperial(dia, selectedDuct.h5 * 25.4);
        selectedDuct.width6 = getwidthImperial(dia, selectedDuct.h6 * 25.4);
      } else {
        let flow = selectedDuct.airQuantity / 2.1188799727597;
        let h = selectedDuct.height * 25.4;
        let fr = selectedDuct.frictionLoss;
        fr *= 249.08890833333 / 30.48;
        var dia =
          0.0344198 *
          Math.pow(0.9 / fr, 1 / 4.86) *
          Math.pow(flow, 1.82 / 4.86);
        var velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
        var area = (Math.PI * Math.pow(dia, 2)) / 4;

        let txtdiameter = Math.round(dia / 0.0254);
        let Txtflowarea = (area / (0.0254 * 0.0254)).toFixed(1);
        let txtVelocity = Math.round(velo * 196.8);

        selectedDuct.width = getwidthImperial(dia, h);
        selectedDuct.diaMeter = txtdiameter;
        selectedDuct.velocity = txtVelocity;
        selectedDuct.flowArea = Txtflowarea;

        selectedDuct.h1 = 12;
        selectedDuct.h2 = 14;
        selectedDuct.h3 = 16;
        selectedDuct.h4 = 18;
        selectedDuct.h5 = 20;
        selectedDuct.h6 = 22;

        selectedDuct.width1 = getwidthImperial(dia, selectedDuct.h1 * 25.4);
        selectedDuct.width2 = getwidthImperial(dia, selectedDuct.h2 * 25.4);
        selectedDuct.width3 = getwidthImperial(dia, selectedDuct.h3 * 25.4);
        selectedDuct.width4 = getwidthImperial(dia, selectedDuct.h4 * 25.4);
        selectedDuct.width5 = getwidthImperial(dia, selectedDuct.h5 * 25.4);
        selectedDuct.width6 = getwidthImperial(dia, selectedDuct.h6 * 25.4);
      }

      if (selectedDuct.frictioninputStatus === true) {
        selectedDuct.frictioninputStatus = true;
        selectedDuct.frictionStatus = true;
      } else {
        selectedDuct.velocityinputStatus = true;
        selectedDuct.velocityStatus = true;
      }
      const updatedDucts = updateDuctList(ducts);
      setDucts(updatedDucts);
      setSelectedDuct(selectedDuct);
    } else {
      if (
        !unpaidUserData?.airQuantity ||
        isNaN(parseFloat(unpaidUserData?.airQuantity))
      ) {
        // setUnitsBoxStatus(false)
        Swal.fire("Please Enter Air Quantity.");
        return;
      }
      if (!unpaidUserData.frictionLoss && !unpaidUserData.velocity) {
        Swal.fire("Please provide either friction loss or velocity");
        return;
      }

      if (unpaidUserData.frictionLoss && unpaidUserData.velocity) {
        Swal.fire("Please delete either friction loss or velocity");
        return;
      }
      if (unpaidUserData.velocity) {
        //let FlowArea = calcuateImperialFlowArea();
        let FlowArea = Math.round(
          unpaidUserData.airQuantity /
            2.1188799727597 /
            1000 /
            (unpaidUserData.velocity / 196.8) /
            (0.0254 * 0.0254)
        );
        let RoundDuctDiameter = CalcualteImperialDuctDiameter(FlowArea);
        let frictionLoss = calculateFrictionLoss(
          unpaidUserData.airQuantity,
          unpaidUserData.velocity,
          0.9
        );
        let h = unpaidUserData.height;
        h *= 25.4;
        let r = 50.8;

        let flow = unpaidUserData.airQuantity / 2.1188799;
        let area = flow / 1000 / (unpaidUserData.velocity / 196.8);
        let dia = Math.pow((area * 4) / Math.PI, 0.5);

        unpaidUserData.width = getwidthImperial(dia, h);
        unpaidUserData.diaMeter = RoundDuctDiameter;
        unpaidUserData.frictionLoss = frictionLoss.toFixed(2);
        unpaidUserData.flowArea = FlowArea;

        unpaidUserData.h1 = 12;
        unpaidUserData.h2 = 14;
        unpaidUserData.h3 = 16;
        unpaidUserData.h4 = 18;
        unpaidUserData.h5 = 20;
        unpaidUserData.h6 = 22;

        unpaidUserData.width1 = getwidthImperial(dia, unpaidUserData.h1 * 25.4);
        unpaidUserData.width2 = getwidthImperial(dia, unpaidUserData.h2 * 25.4);
        unpaidUserData.width3 = getwidthImperial(dia, unpaidUserData.h3 * 25.4);
        unpaidUserData.width4 = getwidthImperial(dia, unpaidUserData.h4 * 25.4);
        unpaidUserData.width5 = getwidthImperial(dia, unpaidUserData.h5 * 25.4);
        unpaidUserData.width6 = getwidthImperial(dia, unpaidUserData.h6 * 25.4);
      } else {
        let flow = unpaidUserData.airQuantity / 2.1188799727597;
        let h = unpaidUserData.height * 25.4;
        let fr = unpaidUserData.frictionLoss;
        fr *= 249.08890833333 / 30.48;
        var dia =
          0.0344198 *
          Math.pow(0.9 / fr, 1 / 4.86) *
          Math.pow(flow, 1.82 / 4.86);
        var velo = 10.91 * Math.pow(fr, 0.54) * Math.pow(dia, 0.67);
        var area = (Math.PI * Math.pow(dia, 2)) / 4;

        let txtdiameter = Math.round(dia / 0.0254);
        let Txtflowarea = (area / (0.0254 * 0.0254)).toFixed(1);
        let txtVelocity = Math.round(velo * 196.8);

        unpaidUserData.width = getwidthImperial(dia, h);
        unpaidUserData.diaMeter = txtdiameter;
        unpaidUserData.velocity = txtVelocity;
        unpaidUserData.flowArea = Txtflowarea;

        unpaidUserData.h1 = 12;
        unpaidUserData.h2 = 14;
        unpaidUserData.h3 = 16;
        unpaidUserData.h4 = 18;
        unpaidUserData.h5 = 20;
        unpaidUserData.h6 = 22;

        unpaidUserData.width1 = getwidthImperial(dia, unpaidUserData.h1 * 25.4);
        unpaidUserData.width2 = getwidthImperial(dia, unpaidUserData.h2 * 25.4);
        unpaidUserData.width3 = getwidthImperial(dia, unpaidUserData.h3 * 25.4);
        unpaidUserData.width4 = getwidthImperial(dia, unpaidUserData.h4 * 25.4);
        unpaidUserData.width5 = getwidthImperial(dia, unpaidUserData.h5 * 25.4);
        unpaidUserData.width6 = getwidthImperial(dia, unpaidUserData.h6 * 25.4);
      }

      //unpaidUserData.frictioninputStatus = false;
      if (unpaidUserData.frictioninputStatus === true) {
        unpaidUserData.frictioninputStatus = true;
        unpaidUserData.frictionStatus = true;
      } else {
        unpaidUserData.velocityinputStatus = true;
        unpaidUserData.velocityStatus = true;
      }
      setUnpaidUserData({ ...unpaidUserData });
    }
    setUnitsBoxStatus(true);
  };

  const [selectedDuct, setSelectedDuct] = useState({
    frictionLoss: "",
    velocity: "",
    width: "",
    height: "",
  });

  let storedMainDuct3 = null;

  // const [latestFrictionLoss, setLatestFrictionLoss] = useState();

  const handleSelected = (selectedNode) => {
    // setFrictionLossError("")
    // setVelocityError("")
    // setErrorMessage("")
    // setErrorMessages("")
    const foundRecords = ducts.find(
      (item) => item.name === selectedNode?.element?.name
    );
    const getBranch = findRecord(selectedNode.element.name, ducts);
    if (foundRecords !== undefined) {
      setSelectedDuct(foundRecords);
    } else {
      let dataObj;
      if (getBranch.frictionLoss === "") {
        dataObj = {
          ...getBranch,
          frictionLoss: selectedDuct?.frictionLoss,
        };
      } else {
        dataObj = getBranch;
        handlePP(dataObj);
      }
      setSelectedDuct(dataObj);
    }
    setSelected(selectedNode);
  };

  const handlePP = (dataObj) => {
    let h = 200;
    let flow = 0;
    let dia = 0;
    let fr = 0;
    let r = 50;
    let h1 = 250,
      h2 = 300,
      h3 = 350,
      h4 = 400,
      h5 = 500,
      h6 = 600;
    let width1 = "",
      width2 = "",
      width3 = "",
      width4 = "",
      width5 = "",
      width6 = "";
    let level = 1;

    if (user?.paid === "paid") {
      try {
        // if (!dataObj?.name) {
        //   Swal.fire("Select Duct/Branch.");
        //   return;
        // }
        let velo;

        // if (
        //   !dataObj?.airQuantity ||
        //   isNaN(parseFloat(dataObj?.airQuantity))
        // ) {
        //   Swal.fire("Please enter Air Quantity.");
        //   return;
        // }
        const convertFactor = Cbunit === "Imperial" ? 0.0254 : 1;
        if (Cbunit === "SI") {
          flow = dataObj.airQuantity;
        } else if (Cbunit === "Imperial") {
          flow = dataObj.airQuantity / 2.1188799727597;
          h *= 25.4;
          r = 50.8;
        }

        if (dataObj.lockStatus === false) {
          if (level <= 0) {
            return;
          }

          // if (!selectedDuct.frictionLoss && !selectedDuct.velocity) {
          //   Swal.fire("Please provide either Friction loss or Velocity");
          //   return;
          // }

          // if (selectedDuct.frictionLoss && selectedDuct.velocity) {
          //   Swal.fire("Please delete either friction loss or velocity");
          //   return;
          // }

          if (dataObj.frictionLoss) {
            fr = dataObj.frictionLoss;
            if (Cbunit === "Imperial") {
              fr *= 249.08890833333 / 30.48;
            }
            dia = calculateDiameterFromFriction(flow, fr);
            velo = calculateVelocityFromDiameter(flow, dia, fr);
          } else if (dataObj.velocity) {
            velo = dataObj.velocity;
            if (Cbunit === "Imperial") {
              velo *= 196.8;
            }
            dia = calculateDiameterFromVelocity(flow, velo);
            fr = calculateFrictionFromVelocity(velo, dia);
          }

          let w = calculateDuctWidth(dia, dataObj?.height, r);
          var veloResult = undefined;
          if (dataObj.frictionLoss !== "") {
            veloResult = calculateVelo(
              dataObj.frictionLoss,
              calculateDia(dataObj.frictionLoss, flow)
            ).toFixed(2);
          }

          dataObj.width = w;
          dataObj.diaMeter = Math.round((dia * 1000).toFixed(2));
          dataObj.velocity = veloResult !== undefined ? veloResult : velo;
          if (typeof fr === "number") {
            dataObj.frictionLoss = fr.toFixed(2);
          }
          //selectedDuct.frictionLoss = fr?.toFixed(2);
          dataObj.flowArea = (flow / 1000 / velo).toFixed(2);

          for (let i = 1; i <= 6; i++) {
            const hi = eval(`h${i}`);
            const width = calculateDuctWidth(dia, hi * convertFactor, r);
            eval(`width${i} = width`);
          }
        }

        if (dataObj.lockStatus === true) {
          if (dataObj.width === "" && height === "" && h === 0) {
            if (Cbunit === "Imperial") {
              r *= 25.4;
            }
            dia = (1.3 * Math.pow(r * h, 0.625)) / Math.pow(r + h, 0.25);
            fr =
              (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
              Math.pow(dia / 1000, 4.86);

            dataObj.diaMeter = Math.round(
              dia * (Cbunit === "Imperial" ? 0.0254 : 1),
              0
            );
            dataObj.frictionLoss = Math.round(
              fr * (Cbunit === "Imperial" ? 30.48 / 249.08890833333 : 1),
              2
            );
            dataObj.velocity = Math.round(
              10.91 *
                Math.pow(fr, 0.54) *
                Math.pow(dia / 1000, 0.67) *
                (Cbunit === "Imperial" ? 196.85 : 1),
              1
            );
            dataObj.flowArea = (flow / 1000 / dataObj.velocity).toFixed(2);
          }
        }

        if (dataObj.frictioninputStatus === true) {
          dataObj.frictioninputStatus = true;
          dataObj.frictionStatus = true;
        } else {
          dataObj.velocityinputStatus = true;
          dataObj.velocityStatus = true;
        }
        dataObj.width1 = width1;
        dataObj.width2 = width2;
        dataObj.width3 = width3;
        dataObj.width4 = width4;
        dataObj.width5 = width5;
        dataObj.width6 = width6;
        const updatedDucts = updateDuctList(ducts);
        setDucts(updatedDucts);
        setSelectedDuct(dataObj);
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    }
  };

  // const [frictionLossError, setFrictionLossError] = useState(""); // State for friction loss error message

  // const validateFrictionLoss = (value) => {
  //   if(Cbunit === "SI")
  //   {
  //     if (value === "") {
  //       setFrictionLossError(""); // Clear error message when value is empty
  //     } else if (value < 0.05 || value > 1) {
  //       setFrictionLossError("Friction loss must be between 0.05 and 1.");
  //     } else {
  //       setFrictionLossError("");
  //     }
  //   }
  //   else
  //   {
  //     if (value === "") {
  //       setFrictionLossError(""); // Clear error message when value is empty
  //     } else if (value < 0.05 || value > 1) {
  //       setFrictionLossError("Friction loss must be between 0.06 and 0.122");
  //     } else {
  //       setFrictionLossError("");
  //     }
  //   }

  // };

  const handleFrictionLossChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setSelectedDuct({
        ...selectedDuct,
        frictionLoss: newValue,
        velocityStatus: false,
        velocityinputStatus: false,
      });
    } else if (!isNaN(newValue) && newValue >= 0) {
      if (selectedDuct?.frictionLoss !== "") {
        setSelectedDuct({
          ...selectedDuct,
          frictionLoss: newValue,
          velocityStatus: true,
          frictionStatus: false,
        });
      } else {
        setSelectedDuct({
          ...selectedDuct,
          frictionLoss: newValue,
          velocityStatus: true,
        });
      }
    }
    const mainDuct1Index = ducts.findIndex(
      (duct) => duct.name === selectedDuct?.name
    );
    if (mainDuct1Index !== -1) {
      const foundRecords = ducts.find(
        (item) => item.name === selectedDuct?.name
      );
      propagateFrictionLoss(foundRecords, newValue);
      setDucts(ducts);
    }
    // validateFrictionLoss(newValue); // Call the validation function
  };

  const handleUnpaidFrictionLossChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      // If the new value is empty, set velocityStatus to false
      setUnpaidUserData({
        ...unpaidUserData,
        frictionLoss: newValue,
        velocityStatus: false,
        velocityinputStatus: false,
      });
    } else if (!isNaN(newValue) && newValue >= 0) {
      // If the new value is not empty and meets certain conditions, set velocityStatus to true
      if (unpaidUserData?.frictionLoss !== "") {
        setUnpaidUserData({
          ...unpaidUserData,
          frictionLoss: newValue,
          frictionStatus: false,
        });
      } else {
        setUnpaidUserData({
          ...unpaidUserData,
          frictionLoss: newValue,
          velocityStatus: true,
        });
      }
    }

    // validateFrictionLoss(newValue);
  };

  // const [velocityError, setVelocityError] = useState(""); // State for velocity error message

  // const validateVelocity = (value) => {
  //   if(Cbunit === "SI")
  //   {
  //     if (value === "") {
  //       setVelocityError(""); // Clear error message when value is empty
  //     } else if (value < 0.5 || value > 10) {
  //       setVelocityError("Velocity must be between 0.5 and 10.");
  //     } else {
  //       setVelocityError("");
  //     }
  //   }
  //   else
  //   {
  //     if (value === "") {
  //       setVelocityError(""); // Clear error message when value is empty
  //     } else if (value < 0.5 || value > 10) {
  //       setVelocityError("Velocity must be between 98.4 and 1968");
  //     } else {
  //       setVelocityError("");
  //     }
  //   }
  // };

  const handleVelocityChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setSelectedDuct({
        ...selectedDuct,
        velocity: newValue,
        frictionStatus: false,
        frictioninputStatus: false,
        velocityinputStatus: false,
        velocityStatus: false,
      });
    } else {
      setSelectedDuct({
        ...selectedDuct,
        velocity: newValue,
        frictionStatus: true,
        frictioninputStatus: true,
      });
    }

    // validateVelocity(newValue)
  };

  const handleUnpaidVelocityChange = (e) => {
    const { name, value } = e.target;
    if ((name, value === "")) {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              [name]: value,
              ["frictionStatus"]: false,
              ["frictioninputStatus"]: false,
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };

      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);

      const payload = {
        ...selectedDuct,
        [name]: value,
        ["frictionStatus"]: false,
        ["frictioninputStatus"]: false,
      };
      setSelectedDuct(payload);
      if (user !== "paid") {
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["frictionStatus"]: false,
          ["frictioninputStatus"]: false,
          ["velocityStatus"]: false,
          ["velocityinputStatus"]: false,
        };
        setUnpaidUserData(payloadOne);
      }
    } else {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              [name]: value,
              ["frictionStatus"]: true,
              ["frictioninputStatus"]: true,
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };

      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);

      const payload = {
        ...selectedDuct,
        [name]: value,
        ["frictionStatus"]: true,
        ["frictioninputStatus"]: true,
      };
      setSelectedDuct(payload);
      if (user !== "paid") {
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["frictionStatus"]: true,
          ["frictioninputStatus"]: true,
        };
        setUnpaidUserData(payloadOne);
      }
    }
    // validateVelocity(value);
  };
  // const [errorMessage, setErrorMessage] = useState("");
  // const [errorMessages, setErrorMessages] = useState("");

  const handleUnpaidWidthChange = (e) => {
    // const newValue = event.target.value;
    // setUnpaidUserData({
    //   ...unpaidUserData,
    //   width: newValue,
    // });
    // validateWidth(newValue);
    const { name, value } = e.target;
    if (Cbunit === "SI" && value >= 100 && value <= 1000) {
      if (name === "width") {
        handleCalculationforHeight(e.target);
      }

      if (unpaidUserData.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
      if (name === "width") {
        imperialSliderCalculation(e.target);
      }

      if (unpaidUserData.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    } else {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
      };
      setUnpaidUserData(payloadOne);
    }

    if (user !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
        ["velocityStatus"]: true,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  // const validateHeight = (value) => {
  //   if(Cbunit === "SI"){
  //     if (value === "") {
  //       setErrorMessage(""); // Clear error message when value is empty
  //     } else if (value < 100 || value > 1000) {
  //       setErrorMessage("Height must be between 100 and 1000.");
  //     } else {
  //       setErrorMessage("");
  //     }
  //   } else {
  //     if (value === "") {
  //       setErrorMessage(""); // Clear error message when value is empty
  //     } else if (value < 4 || value > 40) {
  //       setErrorMessage("Height must be between 4 and 40.");
  //     } else {
  //       setErrorMessage("");
  //     }
  //   }

  // };

  // const validateWidth = (value) => {
  //   if(Cbunit === "SI"){
  //     if (value === "") {
  //       setErrorMessages(""); // Clear error message when value is empty
  //     } else if (value < 100 || value > 1000) {
  //       setErrorMessages("Width must be between 100 and 1000.");
  //     } else {
  //       setErrorMessages("");
  //     }
  //   }else{
  //     if (value === "") {
  //       setErrorMessages(""); // Clear error message when value is empty
  //     } else if (value < 4 || value > 40) {
  //       setErrorMessages("Width must be between 4 and 40.");
  //     } else {
  //       setErrorMessages("");
  //     }
  //   }

  // };

  const handleHeightChange = (e) => {
    // const newValue = event.target.value;
    // setSelectedDuct({
    //   ...selectedDuct,
    //   height: newValue,
    // });

    // validateHeight(newValue);
    const { name, value } = e.target;
    if (Cbunit === "SI" && value >= 100 && value <= 1000) {
      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    }

    let updatedWidth;
    if (user?.paid === "paid") {
      if (Cbunit === "SI" && value >= 100 && value <= 1000) {
        const updatedSelectedDuct = { ...selectedDuct };
        updatedWidth = calculateDuctWidth(
          updatedSelectedDuct.diaMeter / 1000,
          value,
          50
        );

        //New Code

        const updateDuct = (ducts) => {
          return ducts.map((duct) => {
            if (duct.name === selected?.element?.name) {
              return {
                ...duct,
                [name]: value,
                ["width"]:
                  updatedWidth !== undefined
                    ? updatedWidth
                    : selectedDuct.width,
                ["velocityStatus"]: true,
              };
            } else if (duct.children && duct.children.length > 0) {
              return {
                ...duct,
                children: updateDuct(duct.children),
              };
            }
            return duct;
          });
        };

        const updatedDucts = updateDuct(ducts);
        setDucts(updatedDucts);
        const payload = {
          ...selectedDuct,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
          ["velocityStatus"]: true,
        };
        setSelectedDuct(payload);
      } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
        updatedWidth = getWidthWithHeightInImperial(
          value,
          selectedDuct.airQuantity,
          selectedDuct.velocity,
          selectedDuct.width
        );

        //New Code
        const updateDuct = (ducts) => {
          return ducts.map((duct) => {
            if (duct.name === selected?.element?.name) {
              return {
                ...duct,
                [name]: value,
                ["width"]:
                  updatedWidth !== undefined
                    ? updatedWidth
                    : selectedDuct.width,
                ["velocityStatus"]: true,
              };
            } else if (duct.children && duct.children.length > 0) {
              return {
                ...duct,
                children: updateDuct(duct.children),
              };
            }
            return duct;
          });
        };

        const updatedDucts = updateDuct(ducts);
        setDucts(updatedDucts);
        const payload = {
          ...selectedDuct,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
          ["velocityStatus"]: true,
        };
        setSelectedDuct(payload);
      } else {
        const payloadOne = {
          ...selectedDuct,
          [name]: value,
        };
        setSelectedDuct(payloadOne);
      }
    }
  };
  const handleUnpaidHeightChange = (e) => {
    // const newValue = event.target.value;
    // setUnpaidUserData({
    //   ...unpaidUserData,
    //   height: newValue,
    // });
    // validateHeight(newValue);
    const { name, value } = e.target;
    if (Cbunit === "SI" && value >= 100 && value <= 1000) {
      if (unpaidUserData.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
      if (unpaidUserData.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    }

    let updatedWidth;
    if (user?.paid !== "paid") {
      if (Cbunit === "SI" && value >= 100 && value <= 1000) {
        const updatedSelectedDuct = { ...unpaidUserData };
        updatedWidth = calculateDuctWidth(
          updatedSelectedDuct.diaMeter / 1000,
          value,
          50
        );
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined && unpaidUserData?.lockStatus === false
              ? updatedWidth
              : unpaidUserData.width,
          ["velocityStatus"]: true,
        };
        setUnpaidUserData(payloadOne);
      } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
        updatedWidth = getWidthWithHeightInImperial(
          value,
          unpaidUserData.airQuantity,
          unpaidUserData.velocity,
          unpaidUserData.width
        );
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined && unpaidUserData?.lockStatus === false
              ? updatedWidth
              : unpaidUserData.width,
          ["velocityStatus"]: true,
        };
        setUnpaidUserData(payloadOne);
      } else {
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
        };
        setUnpaidUserData(payloadOne);
      }
    }
  };

  const addMainDuct = () => {
    const existingNumbers = ducts.map((duct) => {
      const match = duct.parentDuctName.match(/Main-Duct-(\d+)/);
      return match ? parseInt(match[1]) : 0;
    });

    const maxNumber = Math.max(...existingNumbers);

    // Calculate the next main duct number
    const newKey = maxNumber === -Infinity ? 0 + 1 : maxNumber + 1;
    // Create a new node object with the key and label
    const newNode = {
      name: `Main-Duct-${newKey}`,
      airQuantity: "",
      frictionLoss: "",
      velocity: "",
      frictionStatus: false,
      frictioninputStatus: false,
      velocityStatus: false,
      velocityinputStatus: false,
      diaMeter: "",
      width: "",
      height: Cbunit === "SI" ? 200 : 10,
      flowArea: "",
      widthStatus: true,
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      lockStatus: false,
      remark: "",
      parentDuctName: `Main-Duct-${newKey}`,
      childDuctName: `Main-Duct-${newKey}`,
      children: [],
    };
    setDucts([...ducts, newNode]);
  };

  const addMultipleMainDucts = async () => {
    const result = await Swal.fire({
      title: "Enter the number of ducts",
      input: "number",
      title: "Enter the number of ducts",
      input: "number",
      inputAttributes: {
        step: 1,
        min: 1,
        max: 100,
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value || !/^\d+$/.test(value)) {
          return "Please enter a valid numeric value.";
        }
        if (parseInt(value) > 100) {
          return `You can add up to 100 Ducts at a time.`;
        }
        return null;
      },
    });

    if (result.isConfirmed) {
      const input = result.value;

      if (input === "" || isNaN(input)) {
        Swal.fire("Please enter a valid numeric value.");
        return;
      }

      const count = parseInt(input);

      if (count > 0) {
        if (count <= 100) {
          let newDucts = [];
          const existingNumbers = ducts.map((duct) => {
            const match = duct.parentDuctName.match(/Main-Duct-(\d+)/);
            return match ? parseInt(match[1]) : 0;
          });
          const maxNumber = Math.max(...existingNumbers);
          const newKey = maxNumber !== -Infinity && maxNumber;
          let ductCount = newKey;
          for (let i = 0; i < count; i++) {
            ductCount++;
            newDucts.push({
              name: `Main-Duct-${ductCount}`,
              airQuantity: "",
              frictionLoss: "",
              velocity: "",
              frictionStatus: false,
              frictioninputStatus: false,
              velocityStatus: false,
              velocityinputStatus: false,
              diaMeter: "",
              width: "",
              height: Cbunit === "SI" ? 200 : 10,
              flowArea: "",
              widthStatus: true,
              width1: "",
              width2: "",
              width3: "",
              width4: "",
              width5: "",
              width6: "",
              lockStatus: false,
              remark: "",
              children: [],
            });
          }
          setDucts([...ducts, ...newDucts]);
        } else {
          Swal.fire("You can add a maximum of 100 ducts at a time.");
        }
      }
    }
  };

  const findHighestBranchIndex = (node, highestIndex) => {
    if (!node.children || node.children.length === 0) {
      return highestIndex;
    }
    for (const child of node.children) {
      const branchIndex = parseInt(child?.childDuctName?.split("-")[1], 10);
      if (!isNaN(branchIndex) && branchIndex > highestIndex) {
        highestIndex = branchIndex;
      }
      highestIndex = findHighestBranchIndex(child, highestIndex);
    }

    return highestIndex;
  };

  const addBranchToDuct = () => {
    if (!selected) {
      Swal.fire("Select Duct/Branch.");
      return;
    }

    const parentNode = findNode(ducts, selectedDuct?.name);
    let highestIndex = findHighestBranchIndex({ children: ducts }, 0);

    // Generate a new name for the branch with an incremented index
    const newIndex = highestIndex + 1;
    const newNode = {
      // name: `Branch-${Math.random().toString(36).substr(2, 9)}`,
      name: `Branch-${newIndex}`,
      airQuantity: "",
      frictionLoss: selectedDuct?.frictionLoss,
      velocity: "",
      frictionStatus: false,
      frictioninputStatus: false,
      velocityStatus: selectedDuct?.velocityStatus,
      diaMeter: "",
      width: "",
      height: Cbunit === "SI" ? 200 : 10,
      flowArea: "",
      widthStatus: true,
      width1: "",
      width2: "",
      width3: "",
      width4: "",
      width5: "",
      width6: "",
      lockStatus: false,
      remark: "",
      parentDuctName: parentNode?.parentDuctName,
      childDuctName: `Branch-${newIndex}`,
      children: [],
    };
    // If the parent node exists, push the new node to its children array
    if (parentNode) {
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(newNode);
    }

    // Update the tree data state with the new array
    setDucts([...ducts]);
  };

  const addMultipleBranchesToSelectedDuct = async () => {
    if (!selected) {
      Swal.fire("Select Duct/Branch.");
      return;
    }

    const { value: countInput } = await Swal.fire({
      title: "Enter the number of branches",
      input: "number",
      inputAttributes: {
        step: 1,
        min: 1,
        max: 100,
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value || !/^\d+$/.test(value)) {
          return "Please Enter a valid numeric value.";
        }
        if (parseInt(value) > 100) {
          return `You can add up to 100 branches at a time.`;
        }
        return null;
      },
    });

    if (countInput) {
      const count = parseInt(countInput);
      const parentNode = findNode(ducts, selected?.element?.name);
      let highestIndex = findHighestBranchIndex({ children: ducts }, 0);

      // Generate a new name for the branch with an incremented index
      const newIndex = highestIndex + 1;
      for (let i = 0; i < count; i++) {
        const newNode = {
          name: `Branch-${newIndex + i}`,
          airQuantity: "",
          frictionLoss: selectedDuct?.frictionLoss,
          velocity: "",
          frictionStatus: false,
          frictioninputStatus: false,
          velocityStatus: false,
          velocityinputStatus: false,
          diaMeter: "",
          width: "",
          height: Cbunit === "SI" ? 200 : 10,
          flowArea: "",
          widthStatus: true,
          width1: "",
          width2: "",
          width3: "",
          width4: "",
          width5: "",
          width6: "",
          lockStatus: false,
          remark: "",
          parentDuctName: "",
          childDuctName: "",
          children: [],
        };
        if (parentNode) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          parentNode.children.push(newNode);
        }
      }
      setDucts([...ducts]);
    }
  };

  const removeDuct = () => {
    if (!selected) {
      Swal.fire("Select Duct/Branch.");
      return;
    }

    const node = findNode(ducts, selected?.element?.name);

    if (!node) {
      Swal.fire("Selected Duct/Branch not found.");
      return;
    }

    const parentNode = findParent(ducts, selected?.element?.name);

    if (parentNode) {
      if (parentNode.children) {
        const index = parentNode.children.findIndex(
          (child) => child.name === selected?.element?.name
        );
        if (index !== -1) {
          parentNode.children.splice(index, 1);
          setDucts([...ducts]);

          // Update the selected state to the previous or next branch if available
          if (parentNode.children.length > 0) {
            const newIndex = Math.min(index, parentNode.children.length - 1);
            setSelected({ element: parentNode.children[newIndex] });
            const removeRecord = parentNode.children[newIndex];
            const newNode = {
              name: removeRecord?.name,
              airQuantity: removeRecord?.airQuantity,
              frictionLoss: removeRecord?.frictionLoss,
              velocity: removeRecord?.velocity,
              frictionStatus: removeRecord?.frictionStatus,
              velocityStatus: removeRecord?.velocityStatus,
              diaMeter: removeRecord?.diaMeter,
              width: removeRecord?.width,
              height: removeRecord?.height,
              flowArea: removeRecord?.flowArea,
              widthStatus: removeRecord?.widthStatus,
              width1: removeRecord?.width1,
              width2: removeRecord?.width2,
              width3: removeRecord?.width3,
              width4: removeRecord?.width4,
              width5: removeRecord?.width5,
              width6: removeRecord?.width6,
              lockStatus: removeRecord?.lockStatus,
              remark: removeRecord?.remark,
              parentDuctName: removeRecord?.parentDuctName,
              childDuctName: removeRecord?.childDuctName,
              children: [],
            };
            setSelectedDuct(newNode);
          } else {
            setSelected(parentNode.name); // Set the parent node as selected after removing the last branch
            const newIndex = Math.min(index, parentNode.children.length - 1);
            setSelected({ element: parentNode.children[newIndex] });
            const newNode = {
              name: parentNode?.name,
              airQuantity: parentNode?.airQuantity,
              frictionLoss: parentNode?.frictionLoss,
              velocity: parentNode?.velocity,
              frictionStatus: parentNode?.frictionStatus,
              velocityStatus: parentNode?.velocityStatus,
              diaMeter: parentNode?.diaMeter,
              width: parentNode?.width,
              height: parentNode?.height,
              flowArea: parentNode?.flowArea,
              widthStatus: parentNode?.widthStatus,
              width1: parentNode?.width1,
              width2: parentNode?.width2,
              width3: parentNode?.width3,
              width4: parentNode?.width4,
              width5: parentNode?.width5,
              width6: parentNode?.width6,
              lockStatus: parentNode?.lockStatus,
              remark: parentNode?.remark,
              parentDuctName: parentNode?.parentDuctName,
              childDuctName: parentNode?.childDuctName,
              children: [],
            };
            setSelectedDuct(newNode);
          }
        }
      }
    } else {
      // If the parent node is not found, it means we are trying to remove a main duct.
      const index = ducts.findIndex(
        (mainDuct) => mainDuct.name === selected?.element?.name
      );
      if (index !== -1) {
        ducts.splice(index, 1);
        setDucts([...ducts]);

        // Update the selected state to the previous or next main duct if available
        if (ducts.length > 0) {
          const newIndex = Math.min(index, ducts.length - 1);
          setSelected({ element: ducts[newIndex] });
          // if(ducts[newIndex] === 0)
          // {
          const removeRecord = ducts[newIndex];
          const newNode = {
            name: removeRecord.name,
            airQuantity: removeRecord.airQuantity,
            frictionLoss: removeRecord.frictionLoss,
            velocity: removeRecord.velocity,
            frictionStatus: removeRecord.frictionStatus,
            velocityStatus: removeRecord.velocityStatus,
            diaMeter: removeRecord.diaMeter,
            width: removeRecord.width,
            height: removeRecord.height,
            flowArea: removeRecord.flowArea,
            widthStatus: removeRecord.widthStatus,
            width1: removeRecord.width1,
            width2: removeRecord.width2,
            width3: removeRecord.width3,
            width4: removeRecord.width4,
            width5: removeRecord.width5,
            width6: removeRecord.width6,
            lockStatus: removeRecord.lockStatus,
            remark: removeRecord.remark,
            parentDuctName: removeRecord.parentDuctName,
            childDuctName: removeRecord.childDuctName,
            children: [],
          };
          setSelectedDuct(newNode);
          // }
        } else {
          setSelected(null); // No main ducts left, so clear the selected state
          const newNode = {
            name: "",
            airQuantity: "",
            frictionLoss: "",
            velocity: "",
            frictionStatus: false,
            frictioninputStatus: false,
            velocityStatus: false,
            velocityinputStatus: false,
            diaMeter: "",
            width: "",
            height: Cbunit === "SI" ? 200 : 10,
            flowArea: "",
            widthStatus: true,
            width1: "",
            width2: "",
            width3: "",
            width4: "",
            width5: "",
            width6: "",
            lockStatus: false,
            remark: "",
            parentDuctName: "",
            childDuctName: "",
            children: [],
          };
          setSelectedDuct(newNode);
        }
      }
    }
  };

  const findParent = (treeData, key) => {
    if (!treeData || treeData.length === 0) {
      return null;
    }

    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];

      if (node.children) {
        for (let j = 0; j < node.children.length; j++) {
          const child = node.children[j];

          if (child.name === key) {
            return node;
          }
        }

        const result = findParent(node.children, key);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  // ... Rest of the code

  const clearAllDucts = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove all the ducts!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then(async (res) => {
      if (res.isConfirmed) {
        // API request successful, then clear state and navigate

        setLoading(true);
        setDucts([]);
        const apiUrl = API_URL + "/calculation/create-update";

        const id = localStorage.getItem("projectID") ?? 0;

        const payload = {
          id: parseInt(id),
          productName: project,
          unit: Cbunit,
          ductData: [],
          userId: user.id,
        };
        if (id > 0) {
          try {
            await axios.post(apiUrl, payload, {
              headers: { Authorization: "bearer " + user?.token },
            });
          } catch (error) {
            if (error?.response?.status === 401) {
              localStorage.removeItem("user");
              window.location.href = "/Signup";
            }
          }
          setLoading(false);
        }
      }
      setLoading(false);
      window.location.reload();
    });
  };

  const findNode = (treeData, key) => {
    // Base case: if the tree data array is empty or null, return null
    if (!treeData || treeData.length === 0) {
      return null;
    }

    // Loop through each node in the tree data array
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];

      // If the node key matches the given key, return the node
      if (node.name === key) {
        return node;
      }

      // If the node has children, recursively search for the node in its children array
      if (node.children) {
        const result = findNode(node.children, key);
        if (result) {
          return result;
        }
      }
    }

    // If the node is not found, return null
    return null;
  };

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) && value >= 0) {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              [name]: parseFloat(value),
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };

      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);

      const payload = {
        ...selectedDuct,
        [name]: parseFloat(value),
      };
      setSelectedDuct(payload);
    }
  };

  const handleUnpaidChangeValues = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) && value >= 0) {
      const payloadOne = {
        ...unpaidUserData,
        [name]: parseFloat(value),
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleChangeValuesForRemarks = (e) => {
    const { name, value } = e.target;
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);

    const payload = {
      ...selectedDuct,
      [name]: value,
    };
    setSelectedDuct(payload);
    if (user !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleCalculationforHeight = (e) => {
    if (user?.paid === "paid") {
      try {
        let velo;
        if (
          !selectedDuct?.airQuantity ||
          isNaN(parseFloat(selectedDuct?.airQuantity))
        ) {
          Swal.fire("Please Enter Air Quantity.");
          return;
        }

        let h = 200;
        let flow = 0;
        let dia = 0;
        let fr = 0;
        let r = 50;
        let h1 = 250,
          h2 = 300,
          h3 = 350,
          h4 = 400,
          h5 = 500,
          h6 = 600;
        let width1 = "",
          width2 = "",
          width3 = "",
          width4 = "",
          width5 = "",
          width6 = "";
        let level = 1;
        let height =
          e.name === "height"
            ? parseInt(e.value)
            : parseInt(selectedDuct.height);
        let width =
          e.name === "width" ? parseInt(e.value) : parseInt(selectedDuct.width);
        let diameter = Math.trunc(
          (1.3 * Math.pow(width * height, 0.625)) /
            Math.pow(width + height, 0.25)
        );

        let velocity =
          (((selectedDuct.airQuantity / 1000) * 4) /
            (Math.pow(diameter, 2) * 3.14)) *
          1000000;
        let frictionLoss =
          (0.9 *
            Math.pow(0.0344198, 4.86) *
            Math.pow(selectedDuct.airQuantity, 1.82)) /
          Math.pow(diameter / 1000, 4.86);
        let flowArea = (selectedDuct.airQuantity / 1000 / velocity).toFixed(2);
        selectedDuct.width = width;
        selectedDuct.diaMeter = diameter;
        selectedDuct.velocity = velocity.toFixed(1);
        selectedDuct.frictionLoss = frictionLoss.toFixed(2);
        selectedDuct.flowArea = flowArea;
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    } else {
      try {
        let velo;
        if (
          !unpaidUserData?.airQuantity ||
          isNaN(parseFloat(unpaidUserData?.airQuantity))
        ) {
          Swal.fire("Please Enter Air Quantity.");
          return;
        }

        let h = 200;
        let flow = 0;
        let dia = 0;
        let fr = 0;
        let r = 50;
        let h1 = 250,
          h2 = 300,
          h3 = 350,
          h4 = 400,
          h5 = 500,
          h6 = 600;
        let width1 = "",
          width2 = "",
          width3 = "",
          width4 = "",
          width5 = "",
          width6 = "";
        let level = 1;
        let height =
          e.name === "height"
            ? parseInt(e.value)
            : parseInt(unpaidUserData.height);
        let width =
          e.name === "width"
            ? parseInt(e.value)
            : parseInt(unpaidUserData.width);
        let diameter = Math.trunc(
          (1.3 * Math.pow(width * height, 0.625)) /
            Math.pow(width + height, 0.25)
        );
        let velocity =
          (((unpaidUserData.airQuantity / 1000) * 4) /
            (Math.pow(diameter, 2) * 3.14)) *
          1000000;
        let frictionLoss =
          (0.9 *
            Math.pow(0.0344198, 4.86) *
            Math.pow(unpaidUserData.airQuantity, 1.82)) /
          Math.pow(diameter / 1000, 4.86);
        let flowArea = (unpaidUserData.airQuantity / 1000 / velocity).toFixed(
          2
        );

        unpaidUserData.width = width;
        unpaidUserData.diaMeter = diameter;
        unpaidUserData.velocity = velocity.toFixed(1);
        unpaidUserData.frictionLoss = frictionLoss.toFixed(2);
        unpaidUserData.flowArea = flowArea;
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    }
  };

  function imperialSliderCalculation(e) {
    if (user?.paid === "paid") {
      function calculateDiameter(w, h) {
        return (1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25);
      }

      function calculateFrictionLoss(flow, dia) {
        return (
          (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
          Math.pow(dia / 1000, 4.86)
        );
      }

      function calculateVelocity(fr, dia) {
        return 10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67);
      }

      function calculateFlowArea(dia) {
        return (Math.PI * Math.pow(dia / 1000, 2)) / 4;
      }

      let height = e.name === "height" ? e.value : selectedDuct.height;
      let width = e.name === "width" ? e.value : selectedDuct.width;
      let airQuantity = selectedDuct?.airQuantity / 2.1188799727597;

      let diameter = calculateDiameter(width * 25.4, height * 25.4);
      let frictionLoss = calculateFrictionLoss(airQuantity, diameter);

      let velocity = calculateVelocity(frictionLoss, diameter);
      let flowArea = calculateFlowArea(diameter);

      diameter /= 25.4;
      frictionLoss *= 0.0040146307866177 * 30.48;
      velocity *= 196.8;
      flowArea /= 0.0254 * 0.0254;
      selectedDuct.diaMeter = diameter.toFixed(0);
      selectedDuct.frictionLoss = frictionLoss.toFixed(3);
      selectedDuct.velocity = velocity.toFixed(0);
      selectedDuct.flowArea = flowArea.toFixed(2);
    } else {
      function calculateDiameter(w, h) {
        return (1.3 * Math.pow(w * h, 0.625)) / Math.pow(w + h, 0.25);
      }

      function calculateFrictionLoss(flow, dia) {
        return (
          (0.9 * Math.pow(0.0344198, 4.86) * Math.pow(flow, 1.82)) /
          Math.pow(dia / 1000, 4.86)
        );
      }

      function calculateVelocity(fr, dia) {
        return 10.91 * Math.pow(fr, 0.54) * Math.pow(dia / 1000, 0.67);
      }

      function calculateFlowArea(dia) {
        return (Math.PI * Math.pow(dia / 1000, 2)) / 4;
      }

      let height = e.name === "height" ? e.value : unpaidUserData.height;
      let width = e.name === "width" ? e.value : unpaidUserData.width;
      let airQuantity = unpaidUserData?.airQuantity / 2.1188799727597;

      let diameter = calculateDiameter(width * 25.4, height * 25.4);
      let frictionLoss = calculateFrictionLoss(airQuantity, diameter);

      let velocity = calculateVelocity(frictionLoss, diameter);
      let flowArea = calculateFlowArea(diameter);

      diameter /= 25.4;
      frictionLoss *= 0.0040146307866177 * 30.48;
      velocity *= 196.8;
      flowArea /= 0.0254 * 0.0254;

      unpaidUserData.diaMeter = diameter.toFixed(0);
      unpaidUserData.frictionLoss = frictionLoss.toFixed(3);
      unpaidUserData.velocity = velocity.toFixed(0);
      unpaidUserData.flowArea = flowArea.toFixed(2);
    }
  }

  const handleUnitSystem = (e) => {
    setCbunit(e.target.value);

    const payload = {
      ...selectedDuct,
      airQuantity: "",
    };
    setSelectedDuct(payload);
    if (user?.paid !== "paid") {
      setUnpaidUserData((prevData) => ({
        name: "",
        airQuantity: "",
        frictionLoss: "",
        velocity: "",
        frictionStatus: false,
        frictioninputStatus: false,
        velocityStatus: false,
        velocityinputStatus: false,
        diaMeter: "",
        width: "",
        height: Cbunit === "SI" ? 10 : 200,
        flowArea: "",
        widthStatus: true,
        width1: "",
        width2: "",
        width3: "",
        width4: "",
        width5: "",
        width6: "",
        lockStatus: false,
        remark: "",
      }));
    }
    //   ...prevData,
    // frictionStatus:"",
    // velocityStatus:"",
    // height: e.target.value === "SI" ? 200 : 10,
    // frictionLoss: e.target.value === ""   ,
    // velocity: e.target.value === ""  ? { backgroundColor: "white" }   : {} ,
    // airQuantity: e.target.value === "" ,
    // name:"",
    // diaMeter: "",
    // width: ""  ? { backgroundColor: "white" }   : {} ,
    //  flowArea: "",
    //  width1: "",
    // width2: "",
    // width3: "",
    // width4: "",
    // width5: "",
    // width6: "",
    //  remark: "",
    //    }));
    //  }
    //  else{window.location.reload()}
  };

  const handleWidthChange = (e, newValue) => {
    const { name, value } = e.target;
    if (Cbunit === "SI" && value >= 100 && value <= 1000) {
      if (name === "width") {
        handleCalculationforHeight(e.target);
      }
      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else if (Cbunit === "Imperial" && value >= 4 && value <= 40) {
      if (name === "width") {
        imperialSliderCalculation(e.target);
      }

      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    } else {
      const payload = {
        ...selectedDuct,
        [name]: value,
      };
      setSelectedDuct(payload);
    }

    if (user?.paid === "paid") {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              [name]: value,
              ["velocityStatus"]: true,
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };

      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);
      const payload = {
        ...selectedDuct,
        [name]: value,
        ["velocityStatus"]: true,
      };
      setSelectedDuct(payload);
    }

    // validateWidth(value);
  };

  function propagateFrictionLoss(branch, value) {
    branch.frictionLoss = value;
    branch.velocityStatus = true;
    if (branch.children) {
      for (const child of branch.children) {
        propagateFrictionLoss(child, value);
      }
    }
  }

  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    if (Cbunit === "SI") {
      if (name === "width") {
        handleCalculationforHeight(e.target);
      }

      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else {
      if (name === "width") {
        imperialSliderCalculation(e.target);
      }

      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    }

    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
            ["velocityStatus"]: true,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      [name]: value,
      ["velocityStatus"]: true,
    };
    setSelectedDuct(payload);
    if (user !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
        ["velocityStatus"]: true,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleFrictionLossSliderChange = (e) => {
    const { name, value } = e.target;
    // if (Cbunit === "SI") {
    //   if (name === "width") {
    //     handleCalculationforHeight(e.target);
    //   }

    //   if (selectedDuct.lockStatus === true) {
    //     if (name === "height") {
    //       handleCalculationforHeight(e.target);
    //     }
    //   }
    // } else {
    //   if (name === "width") {
    //     imperialSliderCalculation(e.target);
    //   }

    //   if (selectedDuct.lockStatus === true) {
    //     if (name === "height") {
    //       imperialSliderCalculation(e.target);
    //     }
    //   }
    // }

    // const updatedSelectedDuct = { ...selectedDuct };
    // let updatedWidth = calculateDuctWidth(
    //   updatedSelectedDuct.diaMeter / 1000,
    //   value,
    //   50
    // );

    const mainDuct1Index = ducts.findIndex(
      (duct) => duct.name === selectedDuct?.name
    );
    if (mainDuct1Index !== -1) {
      const foundRecords = ducts.find(
        (item) => item.name === selectedDuct?.name
      );
      propagateFrictionLoss(foundRecords, value);
      setDucts(ducts);
    } else {
      const updateDuct = (ducts) => {
        return ducts.map((duct) => {
          if (duct.name === selected?.element?.name) {
            return {
              ...duct,
              ["velocityStatus"]: true,
              ["velocityinputStatus"]: true,
            };
          } else if (duct.children && duct.children.length > 0) {
            return {
              ...duct,
              children: updateDuct(duct.children),
            };
          }
          return duct;
        });
      };
      const updatedDucts = updateDuct(ducts);
      setDucts(updatedDucts);
    }
    const payload = {
      ...selectedDuct,
      [name]: value,
      ["velocityStatus"]: true,
      ["velocityinputStatus"]: true,
    };
    setSelectedDuct(payload);
    if (user !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
        ["velocityStatus"]: true,
        ["velocityinputStatus"]: true,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleHeightSliderChange = (e, newValue) => {
    const { name, value } = e.target;
    if (Cbunit === "SI") {
      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          handleCalculationforHeight(e.target);
        }
      }
    } else {
      if (selectedDuct.lockStatus === true) {
        if (name === "height") {
          imperialSliderCalculation(e.target);
        }
      }
    }

    let updatedWidth;
    if (user?.paid === "paid") {
      if (Cbunit === "SI") {
        const updatedSelectedDuct = { ...selectedDuct };
        updatedWidth = calculateDuctWidth(
          updatedSelectedDuct.diaMeter / 1000,
          value,
          50
        );

        //New Code

        const updateDuct = (ducts) => {
          return ducts.map((duct) => {
            if (duct.name === selected?.element?.name) {
              return {
                ...duct,
                [name]: value,
                ["width"]:
                  updatedWidth !== undefined
                    ? updatedWidth
                    : selectedDuct.width,
                ["velocityStatus"]: true,
              };
            } else if (duct.children && duct.children.length > 0) {
              return {
                ...duct,
                children: updateDuct(duct.children),
              };
            }
            return duct;
          });
        };

        const updatedDucts = updateDuct(ducts);
        setDucts(updatedDucts);
        const payload = {
          ...selectedDuct,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
          ["velocityStatus"]: true,
        };
        setSelectedDuct(payload);
      } else {
        updatedWidth = getWidthWithHeightInImperial(
          value,
          selectedDuct.airQuantity,
          selectedDuct.velocity,
          selectedDuct.width
        );

        //New Code
        const updateDuct = (ducts) => {
          return ducts.map((duct) => {
            if (duct.name === selected?.element?.name) {
              return {
                ...duct,
                [name]: value,
                ["width"]:
                  updatedWidth !== undefined
                    ? updatedWidth
                    : selectedDuct.width,
                ["velocityStatus"]: true,
              };
            } else if (duct.children && duct.children.length > 0) {
              return {
                ...duct,
                children: updateDuct(duct.children),
              };
            }
            return duct;
          });
        };

        const updatedDucts = updateDuct(ducts);
        setDucts(updatedDucts);
        const payload = {
          ...selectedDuct,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined ? updatedWidth : selectedDuct.width,
          ["velocityStatus"]: true,
        };
        setSelectedDuct(payload);
        if (user !== "paid") {
          const payloadOne = {
            ...unpaidUserData,
            [name]: value,
            ["width"]:
              updatedWidth !== undefined ? updatedWidth : unpaidUserData.width,
            ["velocityStatus"]: true,
          };
          setUnpaidUserData(payloadOne);
        }
      }
    } else {
      if (Cbunit === "SI") {
        const updatedSelectedDuct = { ...unpaidUserData };
        updatedWidth = calculateDuctWidth(
          updatedSelectedDuct.diaMeter / 1000,
          value,
          50
        );
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined && unpaidUserData?.lockStatus === false
              ? updatedWidth
              : unpaidUserData.width,
          ["velocityStatus"]: true,
        };
        setUnpaidUserData(payloadOne);
      } else {
        updatedWidth = getWidthWithHeightInImperial(
          value,
          unpaidUserData.airQuantity,
          unpaidUserData.velocity,
          unpaidUserData.width
        );
        const payloadOne = {
          ...unpaidUserData,
          [name]: value,
          ["width"]:
            updatedWidth !== undefined && unpaidUserData?.lockStatus === false
              ? updatedWidth
              : unpaidUserData.width,
          ["velocityStatus"]: true,
        };
        setUnpaidUserData(payloadOne);
      }
    }
    // validateHeight(newValue);
  };

  function getWidthWithHeightInImperial(h, air, velo, width) {
    let flow = air / 2.1188799;
    let area = flow / 1000 / (velo / 196.8);
    let dia = Math.pow((area * 4) / Math.PI, 0.5);
    h = h * 25.4;
    width = getwidthImperial(dia, h);
    return width;
  }

  const handleSliderOneChange = (e) => {
    const { name, value } = e.target;
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selected?.element?.name) {
          return {
            ...duct,
            [name]: value,
            ["frictionStatus"]: true,
            ["frictioninputStatus"]: true,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);

    const payload = {
      ...selectedDuct,
      [name]: value,
      ["frictionStatus"]: true,
      ["frictioninputStatus"]: true,
    };
    setSelectedDuct(payload);
    if (user !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        [name]: value,
        ["frictionStatus"]: true,
        ["frictioninputStatus"]: true,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const marks = [
    {
      value: 0.1,
      label: "0.1",
    },
    {
      value: 0.3,
      label: "0.3",
    },
    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 0.7,
      label: "0.7",
    },

    {
      value: 0.9,
      label: "0.9",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  const marksVelo = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const marksHeight = [
    {
      value: 100,
      label: "100",
    },
    {
      value: 197,
      label: "200",
    },
    {
      value: 301,
      label: "300",
    },
    {
      value: 398,
      label: "400",
    },
    {
      value: 501,
      label: "500",
    },
    {
      value: 599,
      label: "600",
    },
    {
      value: 702,
      label: "700",
    },
    {
      value: 799,
      label: "800",
    },
    {
      value: 903,
      label: "900",
    },
    {
      value: 1000,
      label: "1000",
    },
  ];

  const marksImp = [
    {
      value: 0.01,
      label: "0.01",
    },
    {
      value: 0.037,
      label: "0.03",
    },
    {
      value: 0.061,
      label: "0.06",
    },
    {
      value: 0.086,
      label: "0.08",
    },
    {
      value: 0.11,
      label: "",
    },
    {
      value: 0.122,
      label: "0.12",
    },
  ];

  const marksVeloImp = [
    {
      value: 98.4,
      label: "98",
    },

    {
      value: 590.4,
      label: "590",
    },
    {
      value: 985,
      label: "985",
    },
    {
      value: 1390,
      label: "1390",
    },
    {
      value: 1968,
      label: "1970",
    },
  ];

  const marksHeightImp = [
    {
      value: 4,
      label: "4",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 16,
      label: "16",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 24,
      label: "24",
    },
    {
      value: 28,
      label: "28",
    },
    {
      value: 32,
      label: "32",
    },
    {
      value: 36,
      label: "36",
    },
    {
      value: 40,
      label: "40",
    },
  ];

  const saveDuct = () => {
    const apiUrl = API_URL + "/calculation/create-update";
    const id = localStorage.getItem("projectID") ?? 0;

    const payload = {
      id: parseInt(id),
      productName: project,
      unit: Cbunit,
      ductData: ducts,
      userId: user.id,
    };

    setLoading(true); // Set loading state to true before making the API call

    axios
      .post(apiUrl, payload, {
        headers: { Authorization: "bearer " + user?.token },
      })
      .then((response) => {
        setLoading(false); // Set loading state to false after the API call
        if (
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          Swal.fire({
            icon: "success",
            text: "Calculation saved successfully.",
          });
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.status === 401) {
          localStorage.removeItem("user");
          window.location.href = "/Signup";
        } else {
          // Set loading state to false after the API call
          console.error("Error:", error);
        }
      });
  };

  const handleLockDuctSize = (status) => {
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            ["widthStatus"]: !selectedDuct.lockStatus ? false : true,
            ["frictionStatus"]: !selectedDuct.lockStatus ? true : false,
            ["velocityStatus"]: !selectedDuct.lockStatus ? true : false,
            ["frictioninputStatus"]: !selectedDuct.lockStatus ? true : false,
            ["velocityinputStatus"]: !selectedDuct.lockStatus ? true : false,
            ["lockStatus"]: !selectedDuct.lockStatus ? true : false,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      ["widthStatus"]: !selectedDuct.lockStatus ? false : true,
      ["frictionStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityStatus"]: !selectedDuct.lockStatus ? true : false,
      ["frictioninputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["velocityinputStatus"]: !selectedDuct.lockStatus ? true : false,
      ["lockStatus"]: !selectedDuct.lockStatus ? true : false,
    };
    setSelectedDuct(payload);
    if (user?.paid !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        ["widthStatus"]: !unpaidUserData.lockStatus ? false : true,
        ["frictionStatus"]: !unpaidUserData.lockStatus ? true : false,
        ["velocityStatus"]: !unpaidUserData.lockStatus ? true : false,
        ["frictioninputStatus"]: !unpaidUserData.lockStatus ? true : false,
        ["velocityinputStatus"]: !unpaidUserData.lockStatus ? true : false,
        ["lockStatus"]: !unpaidUserData.lockStatus ? true : false,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleSetDuctName = (e) => {
    const { name, value } = e.target;
    const payload = {
      ...unpaidUserData,
      [name]: e.target.value,
    };
    setUnpaidUserData(payload);
  };

  const handleDuctTreeName = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setTimeout(() => {
        Swal.fire("Duct/Branch Name must be enter.");
        return;
      }, 2000);
    }
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            [name]: value,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };
    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      [name]: value,
    };
    setSelectedDuct(payload);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const exportPdf = () => {
  //   const element = document.getElementById("DuctList-001");
  //   html2pdf(element);
  // };

  const exportExcel = () => {
    if (ducts.length === 0) {
      return;
    }
    const data = flattenArray(ducts);

    const newData = data?.map((item) => {
      return {
        name: item?.name,
        diaMeter: item?.diaMeter,
        width: item?.width,
        height: item?.height,
        flowArea: item?.flowArea,
        airQuantity: item?.airQuantity,
        frictionLoss: item?.frictionLoss,
        velocity: item?.velocity,
      };
    });
    const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet(newData);

    // Apply styles
    const ws = XLSX.utils.aoa_to_sheet([
      Object.keys(newData[0]), // Header row
      ...newData.map((item) => Object.values(item)),
    ]);
    ws["!cols"] = [{ width: 15 }, { width: 10 }]; // Adjust column widths

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, `${project ?? "untitle"}.xlsx`);
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    const xPos = e.clientX;
    const yPos = e.clientY;
    setContextMenuPosition({ left: 514, top: 452 });
    setContextMenuVisible(true);
  };

  const handleVelocityRightClick = (e) => {
    e.preventDefault();
    const xPos = e.clientX;
    const yPos = e.clientY;
    setContextMenuPosition({ left: 514, top: 600 });
    setContextMenuVisible(true);
  };

  const handleUnpaidRightClick = (e) => {
    e.preventDefault();
    const xPos = e.clientX;
    const yPos = e.clientY;
    setContextMenuPosition({ left: 300, top: 452 });
    setContextMenuVisible(true);
  };

  const handleUnpaidVelocityRightClick = (e) => {
    e.preventDefault();
    const xPos = e.clientX;
    const yPos = e.clientY;
    setContextMenuPosition({ left: 300, top: 600 });
    setContextMenuVisible(true);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setContextMenuVisible(false);
    if (user?.paid === "paid" && selectedDuct?.lockStatus) {
      selectedDuct.lockStatus = false;
    } else {
      unpaidUserData.lockStatus = false;
    }
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            ["frictionLoss"]: "",
            ["velocity"]: "",
            ["frictionStatus"]: false,
            ["velocityStatus"]: false,
            ["frictioninputStatus"]: false,
            ["velocityinputStatus"]: false,
            ["width1"]: "",
            ["width2"]: "",
            ["width3"]: "",
            ["width4"]: "",
            ["width5"]: "",
            ["width6"]: "",
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      ["frictionLoss"]: "",
      ["velocity"]: "",
      ["frictionStatus"]: false,
      ["velocityStatus"]: false,
      ["frictioninputStatus"]: false,
      ["velocityinputStatus"]: false,
      ["width1"]: "",
      ["width2"]: "",
      ["width3"]: "",
      ["width4"]: "",
      ["width5"]: "",
      ["width6"]: "",
    };
    setSelectedDuct(payload);
    if (user?.paid !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        ["frictionLoss"]: "",
        ["velocity"]: "",
        ["frictionStatus"]: false,
        ["velocityStatus"]: false,
        ["frictioninputStatus"]: false,
        ["velocityinputStatus"]: false,
        ["width1"]: "",
        ["width2"]: "",
        ["width3"]: "",
        ["width4"]: "",
        ["width5"]: "",
        ["width6"]: "",
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleStatus = (e) => {
    //e.preventDefault();
    setContextMenuVisible(false);
    if (selectedDuct?.lockStatus) {
      Swal.fire("Unlock the duct size to change the friction and velocity");
      return;
    }
    const updateDuct = (ducts) => {
      return ducts.map((duct) => {
        if (duct.name === selectedDuct?.name) {
          return {
            ...duct,
            ["frictionLoss"]: "",
            ["velocity"]: "",
            ["frictionStatus"]: false,
            ["velocityStatus"]: false,
            ["frictioninputStatus"]: false,
            ["velocityinputStatus"]: false,
          };
        } else if (duct.children && duct.children.length > 0) {
          return {
            ...duct,
            children: updateDuct(duct.children),
          };
        }
        return duct;
      });
    };

    const updatedDucts = updateDuct(ducts);
    setDucts(updatedDucts);
    const payload = {
      ...selectedDuct,
      ["frictionLoss"]: "",
      ["velocity"]: "",
      ["frictionStatus"]: false,
      ["velocityStatus"]: false,
      ["frictioninputStatus"]: false,
      ["velocityinputStatus"]: false,
    };
    setSelectedDuct(payload);
    if (user?.paid !== "paid") {
      const payloadOne = {
        ...unpaidUserData,
        ["frictionLoss"]: "",
        ["velocity"]: "",
        ["frictionStatus"]: false,
        ["velocityStatus"]: false,
        ["frictioninputStatus"]: false,
        ["velocityinputStatus"]: false,
      };
      setUnpaidUserData(payloadOne);
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".custom-context-menu")) {
      setContextMenuVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({ orientation: "landscape", compress: true });

    doc.autoTable({
      html: "#my-table-007",
    });

    //doc.save(`${project}.pdf`);

    doc.autoPrint();
    const fileName = `${project}.pdf`;
    //window.open(doc.output('bloburl', { filename: fileName }), '_blank');

    const blob = doc.output("blob");
    const blobUrl = URL.createObjectURL(blob);

    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
        <title>Project Name: ${project}</title>
      </head>
      <body>
        <embed width="100%" height="100%" src="${blobUrl}" type="application/pdf">
      </body>
    </html>
  `);
    printWindow.document.close();
  };

  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginTop: "20%",
  //       }}
  //     >
  //       <Backdrop
  //         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //         open={loading}
  //       >
  //         <CircularProgress />
  //       </Backdrop>
  //     </Box>
  //   );
  // }

  return (
    <div>
      <Helmet>
        <title>Free HVAC Duct Sizing Calculator</title>
        <meta
          name="description"
          content="Easily size ducts with our free online calculator. Efficiently design HVAC systems without cost. Try our user-friendly duct sizing tool today. Quick, free and precise!"
        />
        <link
          rel="canonical"
          href="https://www.ductsizerpro.com/FreeCalculator"
        />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress />
        </Backdrop>
      </Box>
      <section className="container-fluid Calculator_section  py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-lg-12 d-flex justify-content-center align-items-center">
              <h1>Duct Sizing Tool</h1>
            </div>
          </div>
          <div className="row">
            {user?.paid === "paid" && (
              <div className="col-xl-3 col-lg-12 mb-4">
                <div className="row Calculator_subProject_List_area ">
                  <div className="col-xl-12 col-lg-8 col-md-7 col-12">
                    <div
                      className="Calculator_subProject_List mb-3"
                      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                    >
                      <DirectoryTreeView
                        data={ducts}
                        onSelected={handleSelected}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-4 col-md-5 col-12">
                    <div className="Calculator_subProject_buttonList">
                      <button
                        className="btn subProject_btn"
                        style={{ width: "49%", margin: 1 }}
                        onClick={addMainDuct}
                        disabled={buttonStatus}
                      >
                        Add main duct
                      </button>
                      <button
                        className="btn subProject_btn"
                        style={{ width: "49%", margin: 1 }}
                        onClick={addMultipleMainDucts}
                        disabled={buttonStatus}
                      >
                        Add Multiple main duct
                      </button>
                      <button
                        className="btn subProject_btn"
                        style={{ width: "49%", margin: 1 }}
                        onClick={addBranchToDuct}
                        disabled={buttonStatus}
                      >
                        Add Branch
                      </button>
                      <button
                        className="btn subProject_btn"
                        style={{ width: "49%", margin: 1 }}
                        onClick={addMultipleBranchesToSelectedDuct}
                        disabled={buttonStatus}
                      >
                        Add multiple Branches
                      </button>
                      <button
                        className="btn subProject_btn"
                        style={{ width: "49%", margin: 1 }}
                        onClick={() => removeDuct()}
                        disabled={buttonStatus}
                      >
                        Remove
                      </button>
                      <>
                        <button
                          className="btn subProject_btn"
                          style={{ width: "49%", margin: 1 }}
                          onClick={clearAllDucts}
                          disabled={buttonStatus}
                        >
                          Clear all
                        </button>
                      </>
                      <>
                        <button
                          className="btn subProject_btn"
                          onClick={() => saveDuct()}
                          disabled={buttonStatus}
                        >
                          Save calculation
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`col-lg-12 mb-4 ${
                user?.paid === "paid" ? "col-xl-9 " : "col-xl-12"
              }`}
            >
              <div className="row Calculator_projectName_area">
                {user?.paid === "paid" && (
                  <div className="col-lg-6 col-md-12 mb-3">
                    <div className="form-group">
                      <label for="">Project Name</label>
                      <input
                        type="text"
                        name="projectName"
                        disabled
                        value={project}
                        id=""
                        className="form-control"
                        placeholder="XYZ"
                      />
                    </div>
                  </div>
                )}
                <div className="col-lg-4 col-md-12 mb-3">
                  <div className="form-group">
                    <label style={{ width: "120px" }} for="">
                      Unit System
                    </label>
                    <select
                      name="Cbunit"
                      id=""
                      className="form-select"
                      style={{ minWidth: "90px" }}
                      value={Cbunit}
                      disabled={unitsBoxStatus ? true : false}
                      onChange={(e) => handleUnitSystem(e)}
                    >
                      <option value="SI">Sl</option>
                      <option value="Imperial">IMPERIAL</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row align-items-start">
                <div className="col-xl-4 col-lg-6 col-md-12 mx-auto mt-3">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Air Data</h5>
                    <div className="form-group mb-3">
                      {/* <label for="">Duct Name</label>
                      {user?.paid === "paid" ? (
                        <>
                          <input
                            type="text"
                            name="name"
                            id=""
                            className="form-control"
                            value={selectedDuct?.name || ""}
                            placeholder=""
                            onChange={handleDuctTreeName}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name="name"
                            id=""
                            className="form-control"
                            placeholder=""
                            value={unpaidUserData.name}
                            onChange={handleSetDuctName}
                          />
                        </>
                      )} */}
                    </div>
                    <div className="form-group mb-3">
                      {/* <label for=""  style={{ textTransform: Cbunit === "SI" ? "lowercase" : "none" }}>
                        Air Quantity {Cbunit === "SI" ? "( L/s )" : "( CFM )"}
                      </label> */}
                      <label htmlFor="" style={{ textTransform: "none" }}>
                        Air Quantity{" "}
                        {Cbunit === "SI"
                          ? "( L/s )"
                          : "( CFM )".replace(/l/g, "L").replace(/s/g, "S")}
                      </label>

                      {user?.paid === "paid" ? (
                        <>
                          <input
                            type="number"
                            name="airQuantity"
                            id=""
                            className="form-control no-arrow"
                            placeholder=""
                            value={selectedDuct?.airQuantity}
                            onChange={handleChangeValues}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="number"
                            name="airQuantity"
                            id=""
                            className="form-control no-arrow"
                            placeholder=""
                            value={unpaidUserData?.airQuantity}
                            onChange={handleUnpaidChangeValues}
                          />
                        </>
                      )}
                    </div>
                    {user?.paid === "paid" ? (
                      <>
                        <div
                          className="form-group mb-3"
                          onContextMenu={handleRightClick}
                        >
                          {contextMenuVisible && (
                            <div
                              className="custom-context-menu"
                              style={{
                                position: "absolute",
                                left: contextMenuPosition.left,
                                top: contextMenuPosition.top,
                                backgroundColor: "#006bb8",
                                border: "1px solid",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              <div onClick={handleStatus}>Change Frictionp</div>
                              <div onClick={handleStatus}>Change Velocity</div>
                            </div>
                          )}
                          {/* <label for="" style={{ textTransform: Cbunit === "SI" ? "capitalize" : "none" }} >
                            Friction loss{" "}
                            {Cbunit === "SI" ? "( Pa/m )" : "(IN/100Ft)"}
                          </label> */}
                          <label style={{ textTransform: "none" }}>
                            Friction Loss{" "}
                            {Cbunit === "SI"
                              ? "( P" + "a" + "/m )"
                              : "(IN/100Ft)"}
                          </label>

                          <input
                            type="number"
                            value={selectedDuct?.frictionLoss}
                            // onChange={handleInputChange}
                            onChange={handleFrictionLossChange}
                            className="form-control no-arrow"
                            disabled={
                              selectedDuct?.lockStatus
                                ? selectedDuct?.frictionStatus
                                : selectedDuct?.frictioninputStatus
                                ? true
                                : false
                            }
                          />

                          <Box
                            sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 0.5 : 0.08}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 0.05 : 0.006}
                              max={Cbunit === "SI" ? 1 : 0.122}
                              step={Cbunit === "SI" ? 0.05 : 0.006}
                              valueLabelDisplay="auto"
                              // marks={marks}
                              marks={Cbunit === "SI" ? marks : marksImp}
                              name="frictionLoss"
                              value={selectedDuct?.frictionLoss}
                              onChange={handleFrictionLossSliderChange}
                              disabled={selectedDuct?.frictionStatus}
                            />
                          </Box>
                        </div>

                        <div
                          className="form-group mb-3"
                          onContextMenu={handleVelocityRightClick}
                        >
                          {contextMenuVisible && (
                            <div
                              className="custom-context-menu"
                              style={{
                                position: "absolute",
                                left: contextMenuPosition.left,
                                top: contextMenuPosition.top,
                                backgroundColor: "#006bb8",
                                border: "1px solid",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              <div onClick={handleStatus}>Change Friction</div>
                              <div onClick={handleStatus}>Change Velocity</div>
                            </div>
                          )}
                          <label
                            for=""
                            style={{
                              textTransform: "none",
                            }}
                          >
                            Velocity &nbsp;
                            {Cbunit === "SI" ? "( m/s )" : "(FPM)"}
                          </label>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={selectedDuct?.velocity}
                            //onChange={handleInputChange}
                            onChange={handleVelocityChange}
                            // className={`form-control ${Cbunit === 'SI' && (selectedDuct?.velocity < 3 || selectedDuct?.velocity > 9.5) ? 'invalid-value' : ''}`}

                            className={`form-control no-arrow ${
                              (Cbunit === "SI" &&
                                (selectedDuct?.velocity < 3 ||
                                  selectedDuct?.velocity > 9.5) &&
                                selectedDuct.velocity !== "") ||
                              (Cbunit === "Imperial" &&
                                (selectedDuct?.velocity < 590 ||
                                  selectedDuct?.velocity > 1771) &&
                                selectedDuct.velocity !== "")
                                ? "invalid-value"
                                : ""
                            }`}
                            //disabled={selectedDuct?.velocityStatus}
                            disabled={
                              selectedDuct?.lockStatus
                                ? selectedDuct?.velocityStatus
                                : selectedDuct?.velocityinputStatus
                                ? true
                                : false
                            }
                          />
                          <Box
                            sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 5 : 98.4}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 0.5 : 98.4}
                              max={Cbunit === "SI" ? 10 : 1968}
                              step={Cbunit === "SI" ? 0.5 : 98.4}
                              valueLabelDisplay="auto"
                              //marks={marksVelo}
                              marks={Cbunit === "SI" ? marksVelo : marksVeloImp}
                              name="velocity"
                              value={selectedDuct?.velocity}
                              onChange={handleSliderOneChange}
                              disabled={selectedDuct?.velocityStatus}
                            />
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="form-group mb-3"
                          onContextMenu={handleUnpaidRightClick}
                        >
                          {contextMenuVisible && (
                            <div
                              className="custom-context-menu"
                              style={{
                                position: "absolute",
                                left: contextMenuPosition.left,
                                top: contextMenuPosition.top,
                                backgroundColor: "#006bb8",
                                border: "1px solid",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              <div onClick={handleStatus}>Change Friction</div>
                              <div onClick={handleStatus}>Change Velocity</div>
                            </div>
                          )}
                          <label htmlFor="" style={{ textTransform: "none" }}>
                            Friction Loss{" "}
                            {Cbunit === "SI"
                              ? "( P" + "a" + "/m )"
                              : "(IN/100Ft)"}
                          </label>
                          <input
                            type="number"
                            value={unpaidUserData?.frictionLoss}
                            // onChange={handleInputChange}
                            onChange={handleUnpaidFrictionLossChange}
                            className="form-control no-arrow"
                            disabled={
                              unpaidUserData?.lockStatus
                                ? unpaidUserData?.frictionStatus
                                : unpaidUserData?.frictioninputStatus
                                ? true
                                : false
                            }
                          />
                          <Box
                            sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              // defaultValue={Cbunit === "SI" ? 0.5 : 0.08}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 0.05 : 0.006}
                              max={Cbunit === "SI" ? 1 : 0.122}
                              step={Cbunit === "SI" ? 0.05 : 0.006}
                              valueLabelDisplay="auto"
                              // marks={marks}
                              marks={Cbunit === "SI" ? marks : marksImp}
                              name="frictionLoss"
                              value={unpaidUserData?.frictionLoss}
                              onChange={handleFrictionLossSliderChange}
                              disabled={unpaidUserData?.frictionStatus}
                            />
                          </Box>
                        </div>

                        <div
                          className="form-group mb-3"
                          onContextMenu={handleUnpaidVelocityRightClick}
                        >
                          {contextMenuVisible && (
                            <div
                              className="custom-context-menu"
                              style={{
                                position: "absolute",
                                left: contextMenuPosition.left,
                                top: contextMenuPosition.top,
                                backgroundColor: "#006bb8",
                                border: "1px solid",
                                color: "white",
                                cursor: "pointer",
                              }}
                            >
                              <div onClick={handleStatus}>Change Friction</div>
                              <div onClick={handleStatus}>Change Velocity</div>
                            </div>
                          )}
                          <label
                            for=""
                            style={{
                              textTransform: Cbunit === "SI" ? "none" : "none",
                            }}
                          >
                            Velocity &nbsp;
                            {Cbunit === "SI" ? "( m/s )" : "(FPM)"}
                          </label>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            name="velocity"
                            value={unpaidUserData?.velocity}
                            //onChange={handleInputChange}
                            onChange={handleUnpaidVelocityChange}
                            // className={`form-control ${Cbunit === 'SI' && (selectedDuct?.velocity < 3 || selectedDuct?.velocity > 9.5) ? 'invalid-value' : ''}`}

                            className={`form-control no-arrow ${
                              (Cbunit === "SI" &&
                                (unpaidUserData?.velocity < 3 ||
                                  unpaidUserData?.velocity > 9.5) &&
                                unpaidUserData.velocity !== "") ||
                              (Cbunit === "Imperial" &&
                                (unpaidUserData?.velocity < 590 ||
                                  unpaidUserData?.velocity > 1771) &&
                                unpaidUserData.velocity !== "")
                                ? "invalid-value"
                                : ""
                            }`}
                            //disabled={unpaidUserData?.velocityStatus}
                            disabled={
                              unpaidUserData?.lockStatus
                                ? unpaidUserData?.velocityStatus
                                : unpaidUserData?.velocityinputStatus
                                ? true
                                : false
                            }
                          />
                          <Box
                            sx={{ paddingTop: 1, marginLeft: 0, minWidth: 220 }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 5 : 98.4}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 0.5 : 98.4}
                              max={Cbunit === "SI" ? 10 : 1968}
                              step={Cbunit === "SI" ? 0.5 : 98.4}
                              valueLabelDisplay="auto"
                              //marks={marksVelo}
                              marks={Cbunit === "SI" ? marksVelo : marksVeloImp}
                              name="velocity"
                              value={unpaidUserData?.velocity}
                              onChange={handleSliderOneChange}
                              disabled={unpaidUserData?.velocityStatus}
                            />
                          </Box>
                        </div>
                      </>
                    )}
                    <div className="form-group mb-3">
                      {/* <label for="">Surface Roughness</label> */}
                      <label for="">Duct Material</label>
                      <select name="" id="" className="form-select" disabled>
                        <option value="" selected disabled>
                          Galvanized Steel
                        </option>
                      </select>
                    </div>
                  </div>
                  <button
                    className="btn btn_viewDuct"
                    onClick={() => handleMainCalculation(Cbunit)}
                    disabled={buttonStatus}
                  >
                    Calculate
                  </button>
                  <button
                    className="btn btn_viewDuct"
                    disabled={buttonStatus}
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 mx-auto mt-3">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">Duct Size</h5>
                    <div className="form-group mb-3">
                      <label
                        for=""
                        style={{
                          textTransform: "none",
                        }}
                      >
                        Round Duct Diameter{" "}
                        {Cbunit === "SI" ? "( mm )" : "( IN )"}
                      </label>
                      <input
                        type="text"
                        name="diaMeter"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        value={
                          selectedDuct?.diaMeter !== undefined
                            ? selectedDuct?.diaMeter
                            : unpaidUserData?.diaMeter
                        }
                        readonly
                      />
                    </div>

                    {/* <p
                      className="fw-bold"
                      style={{ textAlign: "left", fontSize: "14px" }}
                    >
                      Rectangular duct Dimensions ( mm )
                    </p> */}

                    <label
                      for=""
                      className=""
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        // textTransform: Cbunit === "SI" ? "lowercase" : "none",
                      }}
                    >
                      Rectangular Duct Dimensions{" "}
                      {Cbunit === "SI" ? "( mm )" : "( IN )"}
                    </label>

                    {user?.paid === "paid" ? (
                      <>
                        <div className="form-group mt-2 mb-2">
                          <label for="">Width</label>
                          <input
                            type="number"
                            min="100"
                            max="1000"
                            name="width"
                            value={selectedDuct?.width}
                            // onChange={handleInputChange}
                            onChange={handleWidthChange}
                            className={`form-control no-arrow ${
                              (Cbunit === "SI" &&
                                (selectedDuct?.width >
                                  selectedDuct?.height * 4 ||
                                  selectedDuct?.width <
                                    selectedDuct?.height / 4) &&
                                selectedDuct?.width !== "") ||
                              (Cbunit === "Imperial" &&
                                (selectedDuct?.width >
                                  selectedDuct?.height * 4 ||
                                  selectedDuct?.width <
                                    selectedDuct?.height / 4) &&
                                selectedDuct?.width !== "")
                                ? "invalid-value"
                                : ""
                            }`}
                            disabled={selectedDuct?.widthStatus ? true : false}
                          />

                          <Box
                            sx={{
                              paddingTop: 1,
                              marginLeft: 0,
                              minWidth: 220,
                            }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 500 : 8}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 100 : 4}
                              max={Cbunit === "SI" ? 1000 : 40}
                              step={Cbunit === "SI" ? 6 : 1}
                              valueLabelDisplay="auto"
                              marks={
                                Cbunit === "SI" ? marksHeight : marksHeightImp
                              }
                              name="width"
                              style={{ fontSize: 11 }}
                              value={selectedDuct?.width}
                              onChange={handleSliderChange}
                              disabled={selectedDuct?.widthStatus}
                            />
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group mt-2 mb-2">
                          <label for="">Width</label>

                          <input
                            type="number"
                            min="0"
                            max="100"
                            name="width"
                            value={unpaidUserData?.width}
                            // onChange={handleInputChange}
                            onChange={handleUnpaidWidthChange}
                            className={`form-control no-arrow ${
                              (Cbunit === "SI" &&
                                (unpaidUserData?.width >
                                  unpaidUserData?.height * 4 ||
                                  unpaidUserData?.width <
                                    unpaidUserData?.height / 4) &&
                                unpaidUserData?.width !== "") ||
                              (Cbunit === "Imperial" &&
                                (unpaidUserData?.width >
                                  unpaidUserData?.height * 4 ||
                                  unpaidUserData?.width <
                                    unpaidUserData?.height / 4) &&
                                unpaidUserData?.width !== "")
                                ? "invalid-value"
                                : ""
                            }`}
                            disabled={unpaidUserData?.widthStatus}
                          />

                          <Box
                            sx={{
                              paddingTop: 1,
                              marginLeft: 0,
                              minWidth: 220,
                            }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 500 : 8}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 100 : 4}
                              max={Cbunit === "SI" ? 1000 : 40}
                              step={Cbunit === "SI" ? 6 : 1}
                              valueLabelDisplay="auto"
                              marks={
                                Cbunit === "SI" ? marksHeight : marksHeightImp
                              }
                              name="width"
                              style={{ fontSize: 11 }}
                              value={unpaidUserData?.width}
                              onChange={handleSliderChange}
                              disabled={unpaidUserData?.widthStatus}
                            />
                          </Box>
                        </div>
                      </>
                    )}

                    {user?.paid === "paid" ? (
                      <>
                        <div className="form-group mb-3">
                          <label for="">height</label>
                          <input
                            type="number"
                            name="height"
                            value={selectedDuct?.height}
                            onChange={handleHeightChange}
                            className="form-control no-arrow"
                          />

                          <Box
                            sx={{
                              paddingTop: 1,
                              marginLeft: 0,
                              minWidth: 220,
                            }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 500 : 12}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 100 : 4}
                              max={Cbunit === "SI" ? 1000 : 40}
                              step={Cbunit === "SI" ? 6 : 1}
                              valueLabelDisplay="auto"
                              marks={
                                Cbunit === "SI" ? marksHeight : marksHeightImp
                              }
                              name="height"
                              value={selectedDuct?.height}
                              onChange={handleHeightSliderChange}
                              disabled={selectedDuct?.heightStatus}
                            />
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group mb-3">
                          <label for="">height</label>
                          <input
                            type="number"
                            min="100"
                            max="1000"
                            name="height"
                            value={unpaidUserData?.height}
                            onChange={handleUnpaidHeightChange}
                            className="form-control no-arrow"
                          />

                          <Box
                            sx={{
                              paddingTop: 1,
                              marginLeft: 0,
                              minWidth: 220,
                            }}
                          >
                            <Slider
                              aria-label="Custom marks"
                              defaultValue={Cbunit === "SI" ? 500 : 12}
                              getAriaValueText={""}
                              min={Cbunit === "SI" ? 100 : 4}
                              max={Cbunit === "SI" ? 1000 : 40}
                              step={Cbunit === "SI" ? 6 : 1}
                              valueLabelDisplay="auto"
                              marks={
                                Cbunit === "SI" ? marksHeight : marksHeightImp
                              }
                              name="height"
                              value={unpaidUserData?.height}
                              onChange={handleHeightSliderChange}
                              disabled={unpaidUserData?.heightStatus}
                            />
                          </Box>
                        </div>
                      </>
                    )}

                    {user?.paid === "paid" ? (
                      <>
                        <button
                          className="btn m-auto btn_lock_Duct mb-3"
                          onClick={() =>
                            handleLockDuctSize(selectedDuct?.lockStatus)
                          }
                          title="Lock duct size to calculate friction loss and velocity"
                          disabled={buttonStatus}
                        >
                          {selectedDuct?.lockStatus ? (
                            <>
                              <i className="fa-solid fa-lock me-2"></i>
                              Unlock Duct Size
                            </>
                          ) : (
                            <>
                              <i className="fa-solid fa-unlock me-2"></i>
                              Lock Duct Size
                            </>
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn m-auto btn_lock_Duct mb-3"
                          onClick={() =>
                            handleLockDuctSize(unpaidUserData?.lockStatus)
                          }
                          title="Lock duct size to calculate friction loss and velocity"
                          disabled={buttonStatus}
                        >
                          {unpaidUserData?.lockStatus ? (
                            <>
                              <i className="fa-solid fa-lock me-2"></i>
                            </>
                          ) : (
                            <>
                              <i className="fa-solid fa-unlock me-2"></i>
                            </>
                          )}
                          Lock Duct Size
                        </button>
                      </>
                    )}
                    <div className="form-group mb-3">
                      <label
                        for=""
                        style={{
                          textTransform: "none",
                        }}
                      >
                        Flow area {Cbunit === "SI" ? "( m² )" : "( IN² )"}
                      </label>
                      <input
                        type="text"
                        name="flowArea"
                        id=""
                        className="form-control no-arrow"
                        placeholder=""
                        value={
                          selectedDuct?.flowArea !== undefined
                            ? selectedDuct?.flowArea
                            : unpaidUserData?.flowArea
                        }
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 mx-auto mt-3">
                  <div className="Calculator_AirData_area">
                    <h5 className="Calculator_AirData_heading">
                      Possible sizing
                    </h5>
                    <div>
                      {Cbunit === "SI" ? (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4">
                            <div>Width ( mm )</div>
                            <div>X</div>
                            <div>Height ( mm )</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width1 !== undefined &&
                                  selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : unpaidUserData?.width1}
                            </div>
                            <div>X</div>
                            <div> 250</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width2 !== undefined &&
                                  selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : unpaidUserData?.width2}
                            </div>
                            <div>X</div>
                            <div> 300</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width3 !== undefined &&
                                  selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : unpaidUserData?.width3}
                            </div>
                            <div>X</div>
                            <div> 350</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width4 !== undefined &&
                                  selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : unpaidUserData?.width4}
                            </div>
                            <div>X</div>
                            <div> 400</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width5 !== undefined &&
                                  selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : unpaidUserData?.width5}
                            </div>
                            <div>X</div>
                            <div> 500</div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width6 !== undefined &&
                                  selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : unpaidUserData?.width6}
                            </div>
                            <div>X</div>
                            <div> 600</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="Possible_sizing_heading mb-3 mt-4">
                            <div>Width( IN )</div>
                            <div>X</div>
                            <div>Height( IN )</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width1 !== undefined &&
                                  selectedDuct?.width1 !== ""
                                ? selectedDuct?.width1
                                : unpaidUserData?.width1}
                            </div>
                            <div>X</div>
                            <div> 12</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width2 !== undefined &&
                                  selectedDuct?.width2 !== ""
                                ? selectedDuct?.width2
                                : unpaidUserData?.width2}
                            </div>
                            <div>X</div>
                            <div> 14</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width3 !== undefined &&
                                  selectedDuct?.width3 !== ""
                                ? selectedDuct?.width3
                                : unpaidUserData?.width3}
                            </div>
                            <div>X</div>
                            <div> 16</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width4 !== undefined &&
                                  selectedDuct?.width4 !== ""
                                ? selectedDuct?.width4
                                : unpaidUserData?.width4}
                            </div>
                            <div>X</div>
                            <div> 18</div>
                          </div>
                          <div className="Possible_sizing_data mb-2">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width5 !== undefined &&
                                  selectedDuct?.width5 !== ""
                                ? selectedDuct?.width5
                                : unpaidUserData?.width5}
                            </div>
                            <div>X</div>
                            <div> 20</div>
                          </div>
                          <div className="Possible_sizing_data">
                            <div>
                              {unpaidUserData?.lockStatus
                                ? ""
                                : selectedDuct?.width6 !== undefined &&
                                  selectedDuct?.width6 !== ""
                                ? selectedDuct?.width6
                                : unpaidUserData?.width6}
                            </div>
                            <div>X</div>
                            <div> 24</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                    <h4 className="text-left" style={{marginTop:"15px"}}><b>QUICK GUIDE:</b></h4>            
                    <div class="guide-section">
                      <h6><b>TO CALCULATE DUCT SIZE:</b></h6>
                      <ol style={{ fontSize: "13px" }}>
                        <li style={{ marginBottom: "5px" }}>Enter Air Quantity (Flow Rate)</li>
                        <li style={{ marginBottom: "5px" }}>Enter either Friction Loss or Velocity. The software will automatically detect the calculation method</li>
                        <li style={{ marginBottom: "5px" }}>Click Calculate and refer to duct size box for results</li>
                        <li style={{ marginBottom: "5px" }}>Click Refresh for a new calculation</li>
                      </ol>
                    </div>

                    <div class="guide-section">
                      <h6><b>TO CALCULATE FRICTION LOSS AND VELOCITY:</b></h6>
                      <ol style={{fontSize: "13px"}}>
                        <li style={{ marginBottom: "5px" }}>Enter Air Quantity (Flow Rate)</li>
                        <li style={{ marginBottom: "5px" }}>Click on "Lock Duct Size" button</li>
                        <li style={{ marginBottom: "5px" }}>Enter your duct size dimensions</li>
                      </ol>
                    </div>
                  {user?.paid === "paid" && (
                    <div className="form-group mt-4">
                      <label for="">Remarks</label>
                      <textarea
                        name="remark"
                        id=""
                        placeholder="Ex.- Remarks"
                        className="form-control"
                        rows="5"
                        value={selectedDuct?.remark}
                        onChange={handleChangeValuesForRemarks}
                      ></textarea>
                    </div>
                  )}

                  {user?.paid === "paid" && (
                    <button
                      className="btn btn_viewDuct"
                      data-bs-toggle="modal"
                      data-bs-target="#btn_viewDuct"
                      onClick={openModal}
                      disabled={buttonStatus}
                    >
                      View all ducts
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="d-flex justify-content-center" style={{fontSize:"13px",marginTop: "2px"}}>
            Our Free Calculator is designed with simplicity in mind, making it
            the Easy To Duct Sizing Tool you've been searching for. Whether
            you're an HVAC professional or a DIY enthusiast, our intuitive Duct
            Sizing Calculator takes the complexity out of ductwork design. Say
            goodbye to the days of tedious calculations – our Free HVAC Duct
            Sizing Calculator streamlines the process, allowing you to focus on
            what matters most: delivering optimal air distribution. What sets us
            apart is our commitment to user convenience. We provide a Free
            Online Velocity Calculator, ensuring precision without the cost—no
            more guesswork – just reliable results at your fingertips. Our
            Online Ductulator Tool stands out as a Professional Duct Size
            Calculator, offering accuracy without the hefty price tag. Trust
            Duct Sizer Pro as your Online Calculator for Sizing HVAC ducts,
            delivering results that meet industry standards. Calculate with
            confidence using our HVAC duct Calculator, Air Duct Sizing
            Calculator, and Duct Diameter Calculator, all in one accessible
            platform. Experience the convenience of an Online Friction Loss
            Calculator, eliminating the guesswork from your projects. Duct Sizer
            Pro simplifies every step, making it the Free Duct Sizer of choice
            for professionals and enthusiasts alike. Don't let friction loss
            hold you back – utilize our Friction Loss Online Calculator for
            seamless duct sizing.
          </p>
        </div>
      </section>
      <div
        className="modal fade"
        id="btn_viewDuct"
        tabindex="-1"
        aria-labelledby="btn_viewDuctLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Ducts List</h5>
              <div className="w-80  text-end">
                <button
                  className="btn btn-primary "
                  style={{ marginRight: "5px" }}
                  onClick={exportExcel}
                >
                  Export Excel
                </button>
                <button className="btn btn-primary" onClick={handleGeneratePdf}>
                  Print Preview
                </button>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body" ref={reportTemplateRef}>
              <DuctsList ducts={ducts} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeCalculator;
