import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import { useState } from 'react';
import Sign_Up from "../assets/Sign_Up.png";
import { Link } from 'react-router-dom';
import { API_URL } from '../Services/UserServices';
import api from '../Services/ApiService';
import ReactGA from 'react-ga4';



const schemaSignIn = yup.object().shape({
  email: yup.string().trim().required("email required")
    .max(200, "email must be at most 200 characters long")
    .matches(/^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/i, "email must be a valid"),
  password: yup.string().required("password required"),
});


const Login = ({ activeTab }) => {

  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  const {
    register: registerSignIn,
    handleSubmit: handleSubmitSignIn,
    reset: resetSignIn,
    formState: { errors: signInErrors },
  } = useForm({
    resolver: yupResolver(schemaSignIn),
  });


  ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title for test" });

  const onSubmitHandlerSignIn = async (data) => {
    setLoading(true);
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual endpoint of your server
      const response = await api.post(
        `${API_URL}/user/login`,
        data
      );

      // Check if the response indicates success (modify the condition based on your API response structure)
      if (response?.data?.statusCode == 200) {

        const userData = response.data.data;
        login(userData, data);
        if (userData.paid == "paid") {
          // Show a success message to the user
          // Swal.fire({
          //   title: "Success!",
          //   text: "login successfully.",
          //   icon: "success",
          //   button: "OK",
          // });
          var currentTime = new Date();
          var startTime = sessionStorage.getItem('startTime');
          var timeDifference = currentTime - startTime;
          var seconds = timeDifference / 1000;
          var minute = Math.floor(seconds / 60);
          var remaingsecond = Math.floor(seconds % 60);

          //test purpose
          debugger;
          const TrackGoogleAnalyticsEvent = (
            category = '',
            event_name = '',
            label = '',
            data = ''
          ) => {

            let event_params = {
              category,
              label,
              ...data
            };
            // Send GA4 Event
            ReactGA.event(event_name, event_params);
          };
          TrackGoogleAnalyticsEvent(
            "User_Time_Tracking",
            userData.name,
            window.location.pathname + window.location.search,
            { SpentTime: `${minute}Min,${remaingsecond}Sec` }
          );
          setInterval(TrackGoogleAnalyticsEvent, 20000);
          navigate("/Project");

        } else {
          // Swal.fire({
          //   title: "Success!",
          //   text: "login successfully.",
          //   icon: "success",
          //   button: "OK",
          // });

          Swal.fire({
            title: "Success!",
            text: "You have 30 days free trial",
          });
          navigate("/");

        }

      } else if (response?.data?.statusCode == 400) {
        Swal.fire({
          title: "Error!",
          text: "Your license has been deactivated please contact the administrator",
          icon: "error",
          button: "ok",
        });
      }
      else if (response?.data?.statusCode == 401) {
        Swal.fire({
          title: "Error!",
          text: "Invalid credentials",
          icon: "error",
          button: "ok",
        });
      }
      else {
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: "ok",
        });
      }

      // Reset the form after successful submission
      // resetSignIn();
    } catch (error) {

      // Handle error response, e.g., show an error message to the user
      // Show a general error message to the user
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        button: "ok",
      });

    }
    setLoading(false);
    // resetSignIn();
  };

  useEffect(() => {
    if (activeTab !== "signIn") {
      resetSignIn();
    }
  }, [activeTab, resetSignIn]);
  return (

    <div className="row">
      <div className="col-md-6 mb-3">
        <div className="card Sign-Up-card-section">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="fw-bold">SIGN IN</h3>
                {/* <p className="Sign_Up_subheading">
                                  On the other hand, we denounce with righteous
                                  indignation and dislike men who are so
                                  beguiled and demoralized by the charms of
                                  pleasure of the moment.
                                </p> */}
              </div>
              <form
                onSubmit={handleSubmitSignIn(
                  onSubmitHandlerSignIn
                )}
              >
                <div className="col-md-12 mb-3">
                  <label className="form-label">Email<span className="redP">*</span></label>
                  <input
                    type="text"
                    className="form-control "
                    {...registerSignIn("email")}
                    placeholder="Ex.- email@gmail.com"
                    autoComplete="off"
                  />
                  <p style={{ color: "red" }}>{signInErrors.email?.message}</p>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Password<span className="redP">*</span></label>
                  <input
                    {...registerSignIn("password")}
                    type="password"
                    className="form-control "
                    placeholder="Ex.- ******** "

                  />
                  <p style={{ color: "red" }}>{signInErrors.password?.message}</p>
                </div>

                {/* <div className="col-md-6 mb-3">
                                  <div className="mb-3 form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="exampleCheck1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="exampleCheck1"
                                    >
                                      Remember me
                                    </label>
                                  </div>
                                </div> */}
                <div className="col-md-12 text-end">
                  <Link
                    to="/ForgotPassword"
                    className="forget_password"
                  >
                    {" "}
                    Forgot Password?
                  </Link>
                </div>
                <div>
                  {loading ? (
                    <button className="btn Sign_btn_submit">Loading..</button>
                  ) : (
                    <button className="btn Sign_btn_submit" >Submit</button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-3">
        <div className="card Sign-Up-card-section-img">
          <div className="card-body">
            <img src={Sign_Up} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>

  );
}


export default Login;